import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import Button from '../../../Components/Form/Button';
import CustomSelect from '../../../Components/Form/CustomSelect';
import TextField from '../../../Components/Form/TextField';
import { Icon, Icons } from '../../../Components/Icon';
import { ContactDto, FormDto } from '../../../types/business.type';
import IndustryList from '../../../Data/Industries';
import StateData from '../../../Data/StateData';
import { useNavigate } from 'react-router-dom';
import { customFlags } from './countryList';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type Props = {
  onSubmit: (values: ContactDto) => void;
  values: FormDto | null;
};

type ICountries = {
  label: string;
  value: string | number;
  image: string;
};

export default function Contact({ onSubmit, values }: Props) {
  const addresses = useMemo(() => {
    if (values?.companyAddresss?.length) {
      return values?.companyAddresss.map((ad) => ({
        address: ad.address ?? '',
        country: ad.country ?? '',
        state: ad.state ?? '',
        postalCode: ad.postalCode ?? '',
        addressType: ad.addressType ?? '',
      }));
    } else {
      return [
        {
          address: '',
          country: '',
          state: '',
          postalCode: '',
          addressType: '',
        },
      ];
    }
  }, [values?.companyAddresss]);

  const formik = useFormik({
    initialValues: {
      type: 'buyer_supplier',
      name: values?.name ?? '',
      email: values?.email ?? '',
      phone: values?.phone ?? '',
      rcNumber: values?.rcNumber ?? '',
      industryType: values?.industryType ?? '',
      companySize: values?.companySize ?? '',
      companyAddresss: addresses,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Company name is required').nullable(),
      email: Yup.string()
        .email('Company Email')
        .required('Company email is required')
        .nullable(),
      phone: Yup.string().nullable(),
      rcNumber: Yup.string().required('RC number is required').nullable(),
      industryType: Yup.string().required('Industry type is required').nullable(),
      companySize: Yup.string().required('Company size is required').nullable(),
      companyAddresss: Yup.array().of(
        Yup.object().shape({
          address: Yup.string().required('Company address is required').nullable(),
          country: Yup.string().required('Country is required').nullable(),
          state: Yup.string().required('City is required').nullable(),
          postalCode: Yup.string().required('Postal code is required').nullable(),
          addressType: Yup.string().required('Address type is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const addMoreAddress = () => {
    formik.values.companyAddresss.push({
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    });
    formik.setFieldValue('companyAddresss', [...formik.values.companyAddresss]);
  };

  const removeAddress = (index: any) => {
    const updatedAddresses = [...formik.values.companyAddresss];
    updatedAddresses.splice(index, 1);
    formik.setFieldValue('companyAddresss', updatedAddresses);
  };
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/auth/login');
  };

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string | number;
    image: string;
  } | null>(null);

  const handleSelection = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const [allCountries, setAllCountries] = useState<ICountries[] | null>([]);

  useEffect(() => {
    const fetchCountry = customFlags?.map((option) => ({
      label: option.name,
      image: option.src,
      value: option.name,
    }));
    setAllCountries(fetchCountry);
  }, [customFlags]);

  return (
    <div>
      <div className="border-b border-[#0000000D] pb-2">
        <h2 className="text-xl text-[#263238] font-semibold">Company Contact</h2>
        <p className="text-base font-normal font-inter text-[#00000066] mt-1">
          Please enter company contact below
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap items-center justify-between gap-4 mt-10">
          <div className="grid flex-1 gap-x-14 gap-y-2 max-[750px]:grid-cols-1 min-[750px]:grid-cols-2">
            <TextField
              name="name"
              type="text"
              value={formik.values.name}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
                formik.handleChange(event);
                formik.setFieldValue('name', newValue);
              }}
              onBlur={formik.handleChange}
              label="Company Name"
              error={formik.touched.name ? formik.errors.name : ''}
              className="bg-onboardTextFieldBg"
              requiredIndicator
            />
            <TextField
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              label="Company Email"
              error={formik.touched.email ? formik.errors.email : ''}
              className="bg-onboardTextFieldBg"
              requiredIndicator
            />
            <div className="flex flex-col gap-2 max-[750px]:mb-[20px]">
              <span>Telephone</span>

              <PhoneInput
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                containerClass="flex gap-2 w-full py-1 text-sm font-inter font-normal placeholder:text-white-dark rounded-lg border border-[#0000001A]"
                inputStyle={{
                  width: '100%',
                  marginLeft: '5px',
                  backgroundColor: '#F9FBFD',
                  outline: 'none',
                  border: 'none',
                }}
                buttonStyle={{
                  backgroundColor: 'white',
                }}
                value={formik.values.phone}
                onChange={(phone) => {
                  const newValue = phone;
                  formik.handleChange(phone);
                  formik.setFieldValue('phone', newValue);
                }}
              />
            </div>
            <TextField
              name="rcNumber"
              type="text"
              value={formik.values.rcNumber}
              onChange={(event) => {
                const newValue = event.target.value; // Allow only numbers
                formik.handleChange(event); // Update formik state
                formik.setFieldValue('rcNumber', newValue); // Set validated value back to formik
              }}
              onBlur={formik.handleChange}
              label="Registration Number"
              error={formik.touched.rcNumber ? formik.errors.rcNumber : ''}
              className="bg-onboardTextFieldBg"
              requiredIndicator
            />
            <CustomSelect
              options={IndustryList.map((industry) => ({
                value: industry,
                label: industry,
              }))}
              selectedOption={{
                value: formik.values.industryType,
                label: formik.values.industryType,
              }}
              handleOptionChange={(option) => {
                formik.setFieldValue('industryType', option?.value);
              }}
              label="Industry Type"
              name="industryType"
              isSearchable
              className="h-[2.7rem] rounded-lg bg-onboardTextFieldBg"
              error={formik.touched.industryType ? formik.errors.industryType : ''}
              requiredIndicator
            />
            <CustomSelect
              options={[
                { value: '1-100', label: '1-100' },
                { value: '101 - 200', label: '101 - 200' },
                { value: '201 - 500', label: '201 - 500' },
                { value: '501 - 1000', label: '501 - 1000' },
                { value: '> 1000', label: '> 1000' },
              ]}
              selectedOption={{
                value: formik.values.companySize,
                label: formik.values.companySize,
              }}
              handleOptionChange={(option) => {
                formik.setFieldValue('companySize', option?.value);
              }}
              label="Company Size"
              name="companySize"
              isSearchable
              className="h-[2.7rem] rounded-lg bg-onboardTextFieldBg"
              error={formik.touched.companySize ? formik.errors.companySize : ''}
              requiredIndicator
              style="max-[750px]:mt-[20px]"
            />
          </div>
        </div>

        <div className="w-full mt-20">
          <div className="border-b border-black pb-2">
            <h2 className="text-xl text-editTextBg font-semibold">Company Address</h2>
            <p className="text-base font-normal font-inter text-contactText mt-1">
              Please enter company address below
            </p>
          </div>
          <div className="gap-4 mt-10">
            {formik.values.companyAddresss.map((ad, index: number) => (
              <div key={index} className="mt-10 lg:mt-4 md:mt-4">
                <div className="flex items-center justify-end mb-[30px]">
                  <Button
                    onClick={() => removeAddress(index)}
                    type="button"
                    variant="outline"
                    size="md"
                    label="Remove Address"
                    className="mt-12 text-white"
                  />
                </div>

                <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                  <TextField
                    name={`companyAddresss[${index}].address`}
                    type="text"
                    value={ad.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Company Address"
                    error={
                      formik.touched.companyAddresss
                        ? formik.errors.companyAddresss?.[index] &&
                          formik.errors.companyAddresss.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.companyAddresss[index])
                            ).address
                          : ''
                        : ''
                    }
                    className="bg-onboardTextFieldBg"
                    requiredIndicator
                  />

                  <CustomSelect
                    options={[
                      ...(allCountries as { label: any; value: any; image: any }[]),
                    ]}
                    selectedOption={selectedOption}
                    handleOptionChange={(option) => {
                      if (option) {
                        formik.setFieldValue(
                          `companyAddresss[${index}].country`,
                          option?.value
                        );
                        handleSelection(option);
                      }
                    }}
                    label="Country"
                    name={`companyAddresss[${index}].country`}
                    isSearchable
                    className="h-[2.7rem] rounded-lg bg-onboardTextFieldBg"
                    error={
                      formik.touched.companyAddresss
                        ? formik.errors.companyAddresss?.[index] &&
                          formik.errors.companyAddresss.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.companyAddresss[index])
                            ).country
                          : ''
                        : ''
                    }
                    requiredIndicator
                  />
                </div>
                <div className="grid grid-cols-1 mb-5 gap-x-14 gap-y-2 md:grid-cols-3 lg:grid-cols-4">
                  <div>
                    <CustomSelect
                      options={StateData.map((st) => ({
                        value: st.state.name,
                        label: st.state.name,
                      }))}
                      selectedOption={{
                        value: ad.state,
                        label: ad.state,
                      }}
                      handleOptionChange={(option) => {
                        formik.setFieldValue(
                          `companyAddresss[${index}].state`,
                          option?.value
                        );
                      }}
                      label="State"
                      name={`companyAddresss[${index}].state`}
                      isSearchable
                      className="h-[2.7rem] rounded-lg bg-onboardTextFieldBg"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).state
                            : ''
                          : ''
                      }
                      requiredIndicator
                      style="max-[750px]:my-[20px]"
                    />
                  </div>
                  <div>
                    <TextField
                      name={`companyAddresss[${index}].postalCode`}
                      type="text"
                      value={ad.postalCode}
                      onChange={(event) => {
                        const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                        formik.handleChange(event); // Update formik state
                        formik.setFieldValue(
                          `companyAddresss[${index}].postalCode`,
                          newValue
                        ); // Set validated value back to formik
                      }}
                      onBlur={formik.handleChange}
                      label="Postal Code"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).postalCode
                            : ''
                          : ''
                      }
                      className="bg-onboardTextFieldBg"
                      requiredIndicator
                    />
                  </div>
                  <div className="md:col-span-2">
                    <CustomSelect
                      options={[
                        {
                          value: 'registeredAddress',
                          label: 'Registered Address',
                        },
                        {
                          value: 'operatingAddress',
                          label: 'Operating Address',
                        },
                      ]}
                      selectedOption={{
                        value: ad.addressType,
                        label: ad.addressType,
                      }}
                      handleOptionChange={(option) => {
                        formik.setFieldValue(
                          `companyAddresss[${index}].addressType`,
                          option?.label
                        );
                      }}
                      label="Address Type"
                      name={`companyAddresss[${index}].addressType`}
                      isSearchable
                      className="h-[2.7rem] rounded-lg bg-onboardTextFieldBg"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).addressType
                            : ''
                          : ''
                      }
                      requiredIndicator
                    />
                  </div>
                </div>
              </div>
            ))}
            <div
              role="button"
              className="flex items-center gap-2"
              tabIndex={0}
              onClick={addMoreAddress}
            >
              <div className="flex items-center gap-3 flex-nowrap">
                <Icon name={Icons.Add} />
                <span className="text-sm font-medium min-w-[150px] text-[#000000]">
                  Add {formik?.values?.companyAddresss?.length > 0 ? 'another' : ''}{' '}
                  address{' '}
                </span>
              </div>
              <span className="h-[1px] w-full bg-black/20 opacity-50 "> </span>
            </div>

            <div className="flex max-[680px]:flex-col items-center justify-between">
              <Button
                type="button"
                variant="outline"
                label="Back"
                className="mt-12 bg-transparent px-5 py-3 text-base min-[680px]:w-52 max-[680px]:w-full"
                loading={formik.isSubmitting}
                onClick={handleGoToLogin}
              />
              <Button
                type="submit"
                variant="primary"
                label="Save & Continue"
                className="mt-12 px-5 py-3 text-base min-[680px]:w-52 max-[680px]:w-full"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
