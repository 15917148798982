import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../Form/TextField';
import CustomSelect from '../../Form/CustomSelect';
import Button from '../../Form/Button';
import UploadPreview from '../../Form/UploadPreview';
import {
  useCreateVendor,
  useInviteVendor,
  useResendInvite,
  useSearchVendor,
} from '../../../hooks/queries-and-mutations/vendor';
import CustomSelectInput from '../../Form/CustomSelectInput';
import { InfoModal } from '../InfoModal';
import UploadFile from '../../Form/UploadFile';
import { VendorType } from '../../../types/vendor.type';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  vendorData: VendorType | null;
  businessId: string;
  setInviteStatus?: (arg: boolean) => void;
};

export default function AddVendorModal({
  onCancel,
  onSuccess,
  businessId,
  vendorData,
  setInviteStatus,
}: Readonly<Props>) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [buttonState, setButtonState] = useState('Create');
  const [toBeReinvitedId, setToBeReInvitedId] = useState('');

  const { mutate, isLoading, data } = useSearchVendor();
  const {
    mutate: inviteVendor,
    isSuccess: isInviteSuccess,
    isError: isInviteError,
  } = useInviteVendor();
  const {
    mutate: createVendor,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useCreateVendor();
  const {
    mutate: resendInvite,
    isSuccess: isResendSuccess,
    isError: isResendError,
  } = useResendInvite();

  const formik = useFormik({
    initialValues: {
      vendorName: '',
      vendorEmail: '',
      vendorType: '',
      goodType: '',
      termOfTrade: '',
      contactName: '',
      contactNumber: '',
      newTermsOfTrade: '',
      contractDocument: [],
    },
    validationSchema: Yup.object({
      vendorName: Yup.string().required('Vendor name is required').nullable(),
      vendorEmail: Yup.string()
        .email('Invalid Email')
        .required('Email is required')
        .nullable(),
      vendorType: Yup.string().required('Please select a vendor type').nullable(),
      goodType: Yup.string().required('Good supplied is required').nullable(),
      termOfTrade: Yup.string().required('Terms of trade is required').nullable(),
      contactName: Yup.string().required('Contact name is required').nullable(),
      newTermsOfTrade: Yup.string().when('termOfTrade', {
        is: 'others',
        then: Yup.string().nullable().required('Please tell us your terms of trade'),
        otherwise: Yup.string().nullable(),
      }),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: { [key: string]: any }) => {
    try {
      let terms = values.termOfTrade;
      if (values.termOfTrade === 'others') {
        terms = values.newTermsOfTrade;
      }
      const formData = new FormData();
      if (selectedVendor) {
        formData.append('vendorID', selectedVendor.value);
      }
      formData.append('vendorName', values.vendorName);
      formData.append('contactName', values.contactName);
      formData.append('contactEmail', values.vendorEmail.toLocaleLowerCase());
      formData.append('contactPhone', values.contactNumber.toString());
      formData.append('goodsSupplied', values.goodType);
      formData.append('termsOfTrade', terms);
      formData.append('type', values.vendorType);

      values.contractDocument.forEach((file: any) => {
        formData.append('contract', file);
      });
      const formDataValues: { [key: string]: FormDataEntryValue } = {};
      Array.from(formData.entries()).forEach(([key, value]) => {
        formDataValues[key] = value;
      });

      if (selectedVendor === null) {
        inviteVendor({ businessId, payload: formData });
      } else {
        createVendor({ businessId, payload: formData });
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      mutate({ payload: { name: value }, businessID: businessId });
    }
  };

  const searchList = useMemo(() => {
    return !isLoading
      ? (data ?? [])
          ?.sort(
            (a, b) =>
              Number(a.isConnectionBlacklisted) - Number(b.isConnectionBlacklisted)
          )
          .map((option: any) => ({
            label: option.name,
            value: option?._id,
            blacklisted: option.isConnectionBlacklisted,
            ...option,
          }))
      : [];
  }, [data, isLoading]);

  const prefillVendor = (selectedValue: any) => {
    setSelectedVendor(selectedValue);

    if (selectedValue.repsDetails.length >= 1) {
      formik.setFieldValue('vendorName', `${selectedValue.name}`);
      formik.setFieldValue(
        'contactName',
        `${selectedValue.repsDetails[0].firstName} ${selectedValue.repsDetails[0].lastName}`
      );
      formik.setFieldValue('vendorEmail', selectedValue.repsDetails[0].email);
    } else {
      formik.setFieldValue('vendorName', selectedValue.name);
      formik.setFieldValue('vendorEmail', selectedValue.email);
      formik.setFieldValue('contactName', '');
    }
  };

  const handleFileDelete = (fileName: string) => {
    const newFile = formik.values.contractDocument.filter(
      (file: File) => file.name !== fileName
    );
    formik.setFieldValue('contractDocument', newFile);
  };

  useEffect(() => {
    if (isInviteSuccess && !isInviteError) {
      // toast.success('Invite sent to Vendor successfully')
      setIsSubmitting(false);
      onSuccess();
    } else if (isInviteError) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInviteError, isInviteSuccess]);

  useEffect(() => {
    if (isCreateSuccess && !isCreateError) {
      setIsSubmitting(false);
      onSuccess();
    } else if (isCreateError) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateError, isCreateSuccess]);

  useEffect(() => {
    if (isResendSuccess && !isResendError && setInviteStatus) {
      setIsSubmitting(false);
      setButtonState('Create');
      setInviteStatus(true);
      onSuccess();
    } else if (isResendError) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendSuccess, isResendError]);

  useEffect(() => {
    if (vendorData && selectedVendor) {
      const search = vendorData?.docs?.filter((res) =>
        res?.vendorID?._id?.includes(selectedVendor?._id)
      );

      setToBeReInvitedId(search[0]?._id);
      if (search[0]?.inviteLinkExpireAt) {
        const inviteLinkExpireAt = new Date(search[0]?.inviteLinkExpireAt);
        const currentDate = new Date();

        if (currentDate < inviteLinkExpireAt) {
          setButtonState('ResendInvite');
        } else {
          setButtonState('Create');
        }
      }
    }
  }, [selectedVendor]);

  return (
    <InfoModal
      width="min-[1100px]:w-[45%] max-[1100px]:w-[60%] min-w-[500px] max-[650px]:min-w-[300px] max-[650px]:w-[90%]"
      className="h-fit"
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[650px]:px-8 max-[650px]:px-0 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 pb-3 text-left border-b max-w-xs">
            <p className="text-xl font-medium text-black font-inter">Add one by one</p>
            <p className="text-sm font-normal font-inter text-black/40">
              Search for a vendor to add them, or invite them if they are not registered
              yet.
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <CustomSelectInput
                options={searchList}
                handleOptionChange={(option, { action }) => {
                  if (action === 'select-option') {
                    prefillVendor(option);
                  } else if (action === 'create-option') {
                    formik.setFieldValue('vendorName', option?.value);
                    setSelectedVendor(null);
                  }
                }}
                onInputChange={(e) => handleSearch(e)}
                label="Vendor Name"
                name="vendorName"
                isSearchable
                className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                error={formik.touched.vendorName ? formik.errors.vendorName : ''}
                placeholder="Search here"
                isLoading={isLoading}
                requiredIndicator
              />
              <div className="grid flex-1 grid-cols-1 pt-5 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="vendorEmail"
                  type="email"
                  value={formik.values.vendorEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Vendor Email"
                  error={formik.touched.vendorEmail ? formik.errors.vendorEmail : ''}
                  className="bg-white"
                  requiredIndicator
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
                <CustomSelect
                  options={[
                    { value: 'Supplier', label: 'Supplier' },
                    { value: 'Buyer', label: 'Buyer' },
                  ]}
                  selectedOption={{
                    value: formik.values.vendorType,
                    label: formik.values.vendorType,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('vendorType', option?.value);
                  }}
                  label="Vendor Type"
                  name="vendorType"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.vendorType ? formik.errors.vendorType : ''}
                  requiredIndicator
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="goodType"
                  type="text"
                  value={formik.values.goodType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label={
                    formik.values.vendorType === 'Buyer'
                      ? 'Good Bought'
                      : 'Goods Supplied'
                  }
                  error={formik.touched.goodType ? formik.errors.goodType : ''}
                  className="bg-white"
                  requiredIndicator
                  style="max-[640px]:mt-4"
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
                <CustomSelect
                  options={[
                    { value: '30 days', label: '30 days' },
                    { value: '60 days', label: '60 days' },
                    { value: '90 days', label: '90 days' },
                    { value: 'others', label: 'others' },
                  ]}
                  selectedOption={{
                    value: formik.values.termOfTrade,
                    label: formik.values.termOfTrade,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('termOfTrade', option?.value);
                  }}
                  label="Terms of Trade"
                  name="termOfTrade"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.termOfTrade ? formik.errors.termOfTrade : ''}
                  requiredIndicator
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="contactName"
                  type="text"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Contact Name"
                  error={formik.touched.contactName ? formik.errors.contactName : ''}
                  className="bg-white"
                  requiredIndicator
                  style="max-[640px]:mt-4"
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
                <TextField
                  name="contactNumber"
                  type="number"
                  value={formik.values.contactNumber.toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Contact Number"
                  error={formik.touched.contactNumber ? formik.errors.contactNumber : ''}
                  className="bg-white"
                  disabled={buttonState === 'ResendInvite' ? true : false}
                />
                {formik.values.termOfTrade === 'others' && (
                  <TextField
                    name="newTermsOfTrade"
                    type="text"
                    value={formik.values.newTermsOfTrade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Type terms of trade"
                    error={
                      formik.touched.newTermsOfTrade ? formik.errors.newTermsOfTrade : ''
                    }
                    className="bg-white"
                    requiredIndicator
                    disabled={buttonState === 'ResendInvite' ? true : false}
                  />
                )}
              </div>
              <UploadFile
                acceptFile={['.jpeg', '.png', '.jpg', '.pdf', '.doc']}
                isMultiple
                label="Contract Document"
                fileName="ContractDocument"
                getMultipleFile={(files) => {
                  formik.setFieldValue('contractDocument', files);
                }}
              />

              {formik.values.contractDocument?.length > 0 && (
                <div className="mt-2">
                  {formik.values.contractDocument.map((file: File) => (
                    <UploadPreview
                      key={file.name}
                      fileName={file.name}
                      removeFile={() => handleFileDelete(file.name)}
                      requiredIndicator
                    />
                  ))}
                </div>
              )}

              <div>
                {buttonState === 'ResendInvite' ? (
                  <Button
                    variant="primary"
                    size="lg"
                    label="Resend Invite"
                    className="mt-12"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || isSubmitting}
                    onClick={() => {
                      resendInvite({
                        businessId,
                        vendorID: toBeReinvitedId,
                      });
                    }}
                  />
                ) : (
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    label="Submit"
                    className="mt-12"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || isSubmitting}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
