import React from 'react';
import { IoIosCheckmark } from 'react-icons/io';

interface IPlans {
  level?: string;
  cost?: string;
  currency?: string;
  time?: string;
  benefits?: string[];
  onClick?: () => void;
  className?: string;
}
const PlansCard: React.FC<IPlans> = ({
  level,
  currency,
  time,
  benefits,
  onClick,
  className,
}) => {
  return (
    <div
      className={`border border-black/20 hover:border-[#0353A4] transition-colors duration-800 h-full min-h-[500px] max-w-[400px] min-[400px]:w-[300px] max-[400px]:w-[250px] max-[400px]:mx-auto rounded-lg p-2`}
    >
      <div
        className={`w-[90%] h-full flex flex-col items-center justify-between max-h-[100%] gap-[10px] m-auto`}
      >
        <div className="h-[100%] flex flex-col w-full ">
          <div className="justify-between w-full h-full ">
            <div className={`flex flex-col group justify-start gap-3 mt-4`}>
              <h3
                className={`${className} scroll-m-20 text-xl font-semibold tracking-tight`}
              >
                {level}{' '}
              </h3>
              <h1 className="group-hover:text-blueTextColor transition-colors duration-800">
                <strong className="text-xl font-semibold text-black group-hover:text-blueTextColor lg:text-xl">{`${currency}`}</strong>{' '}
                <span className="font-medium text-xs text-black/60">
                  {' '}
                  / seat / {time}{' '}
                </span>
              </h1>
              <h3> Enjoy these features</h3>
            </div>
            <ul className={`flex flex-col gap-3 my-4 group text-black/40`}>
              {benefits?.map((benefit, index: number) => (
                <li
                  className={`flex items-start gap-1 font-normal text-xs text-black/40`}
                  key={index}
                >
                  <span className="text-[22px] group-hover:text-blueTextColor transition-colors duration-800">
                    {<IoIosCheckmark />}{' '}
                  </span>
                  <span className=" text-black/70 text-sm group-hover:text-blueTextColor transition-colors duration-800">
                    {benefit}{' '}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <button
            className={`border border-blueTextColor bg-transparent rounded-lg text-blueTextColor p-2 w-full font-inter font-medium`}
            onClick={onClick}
          >
            Subscribe{' '}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlansCard;
