import React, { useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../style/index.css';
import '../style/style.css';
import Step from './Step';
import { motion } from 'framer-motion';
import { MdClose } from 'react-icons/md';
import { RiMenu3Fill } from 'react-icons/ri';

export default function OnBoardingLayout() {
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const contactSteps = [
    { title: 'Company Contact', key: 'contact', id: 1, hasNext: true },
    { title: 'Company Address', key: 'address', id: 2, hasNext: false },
  ];

  const kbySteps = [
    { title: 'Document Upload', key: 'contact', id: 1, hasNext: true },
    { title: 'Director Information', key: 'director', id: 2, hasNext: true },
    { title: 'Representative Information', key: 'representative', id: 3, hasNext: true },
    { title: 'Bank Account', key: 'bank', id: 4, hasNext: false },
  ];

  const details = [{ title: 'Detail Preview', key: 'preview', id: 1, hasNext: false }];

  const determineStep = useMemo(() => {
    if (pathname.includes('contact')) {
      return contactSteps;
    } else if (pathname.includes('kyb')) {
      return kbySteps;
    } else {
      return details;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="grid grid-cols-[340px_auto] max-[1100px]:grid-cols-[70px_auto] max-[450px]:grid-cols-[50px_auto] gap-x-4 min-h-[100vh] h-[100vh] overflow-hidden text-black">
      <nav className=" h-full overflow-hidden shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300 bg-[#0353A4] max-[1100px]:hidden ">
        <div className="h-full bg-[#0353A4] text-white">
          <ul className="mt-14 perfect-scrollbar relative  h-full space-y-0.5 overflow-y-auto overflow-x-hidden p-4 py-0 font-semibold">
            <div className="relative ms-6 mt-9 text-white font-normal text-sm">
              <Step path={pathname} steps={determineStep} />
            </div>
          </ul>
        </div>
      </nav>
      {showMobileMenu && (
        <motion.nav
          initial={{ translateX: '-100%', opacity: 0, zIndex: '99' }}
          animate={{ translateX: '0%', opacity: 1, zIndex: '99' }}
          exit={{ translateX: '-100%', opacity: 1, zIndex: '99' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          className="fixed z-99 left-0 top-0 w-[70%] h-full shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] bg-[#0353A4]"
        >
          <div className="h-full bg-[#0353A4] text-white">
            <ul className="mt-14   perfect-scrollbar relative h-full space-y-0.5 overflow-y-auto overflow-x-hidden p-4 py-0 font-semibold">
              <div className="relative  ms-6 mt-9 text-white font-normal text-sm">
                <Step path={pathname} steps={determineStep} />
              </div>
            </ul>
          </div>
        </motion.nav>
      )}
      <div className="min-[1100px]:hidden max-[1100px]:block h-[100vh] bg-[#0353A4] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] border border-white/30">
        <button
          className="inline-flex items-center justify-center w-full mt-[30px] p-3 hs-collapse-toggle"
          onClick={() => setShowMobileMenu((prevState) => !prevState)}
        >
          <RiMenu3Fill color="white" size={24} />
        </button>
        {showMobileMenu && (
          <button
            className="fixed left-[15px] top-[30px] z-[999] justify-center w-full p-3 hs-collapse-toggle"
            onClick={() => setShowMobileMenu((prevState) => !prevState)}
          >
            <MdClose color="white" size={24} />
          </button>
        )}
      </div>
      {/* end sidebar section */}
      <div className=" flex flex-col items-center min-h-[100vh] bg-onboardingBg overflow-y-scroll overflow-x-hidden">
        <div className="animate__animated py-24 min-w-[1800px]:max-w-[800px] min-[750px]:min-w-[600px] w-[80%] max-[450px]:w-[95%] ">
          {/* start main content section */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
