import React, { Fragment, useEffect, useState } from 'react';
import { InvoiceDoc, InvoiceStatus } from '../../../../types/invoice.type';
import { useGetApproval } from '../../../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { groupDataByLevel, groupSellerAndBuyerByLevel } from '../../../../utils';
import Button from '../../../../Components/Form/Button';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import DeclineModal from '../../../../Components/Modal/Invoice/DeclineModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import { Icon, Icons } from '../../../../Components/Icon';
import { selectUserInformation } from '../../../../selectors/account-selector';
import {
  useApproveInvoice,
  useDeclineInvoice,
} from '../../../../hooks/queries-and-mutations/invoice';
import { toast } from 'react-toastify';
import SendReminderModal from '../../../../Components/Modal/Invoice/SendReminderModal';

type IProps = {
  sellerApprovalNextLevel: string | undefined;
  buyerApprovalNextLevel: string | undefined;
  invoice: InvoiceDoc | undefined;
  getInvoice: () => void;
};

const EnhancedDeclineModal = withCreatePortal(DeclineModal);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);
const EnhancedSendReminderModal = withCreatePortal(SendReminderModal);

export default function ReceiveableApprovalFlow({
  invoice,
  sellerApprovalNextLevel,
  buyerApprovalNextLevel,
  getInvoice,
}: IProps) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSuccessModalForReminder, setShowSuccessModalForReminder] = useState(false);

  const { docs } = useAppSelector(selectBusiness);
  const { user } = useAppSelector(selectUserInformation);

  const { data: approvals } = useGetApproval({ businessId: docs[0]?.businessID?._id });
  const {
    mutate: approveInvoice,
    isSuccess: isApproveInvoiceSuccess,
    isError: isApproveInvoiceError,
  } = useApproveInvoice();
  const {
    mutate: declineInvoice,
    isSuccess: isDeclineInvoiceSuccess,
    isError: isDeclineInvoiceError,
  } = useDeclineInvoice();

  const groupedData = groupDataByLevel(approvals?.docs ?? []);

  const checkUserApproval = (approvalUsers: any): any => {
    //!Seller
    if (docs[0].businessID?._id === invoice?.businessID?._id) {
      // Search through all approval users for the current user's email
      const foundSeller = Object.values(approvalUsers)
        .flat()
        .find((item: any) => item?.email === user?.email);
      if (foundSeller) {
        return foundSeller;
      } else {
        return null;
      }
      //*Buyer
    } else if (docs[0].businessID?._id === invoice?.sellerID?._id) {
      // Search through all approval users for the current user's email
      const foundBuyer = Object.values(approvalUsers)
        .flat()
        .find((item: any) => item?.email === user?.email);
      if (foundBuyer) {
        return foundBuyer;
      } else {
        return null;
      }
    }

    return null;
  };

  const currentUserApproval = checkUserApproval(
    docs[0]?.businessID?._id === invoice?.sellerID?._id
      ? groupedData?.payable //*Buyer
      : groupedData.receivable //!Seller
  );

  const getBackgroundColor = (currentStatus: string) => {
    const status = invoice?.status; // Current invoice status

    // Check if the invoice is awaiting approval from the seller
    const isAwaitingApprovalSeller = status === InvoiceStatus?.AWAITING_APPROVAL_SELLER;

    // If the invoice status is awaiting approval
    if (isAwaitingApprovalSeller) {
      if (currentStatus === InvoiceStatus?.AWAITING_APPROVAL_SELLER) {
        return {
          bg: 'bg-[#0353A4]',
          icon: 'white',
          border: 'after:border-[#0353A4]',
        };
      }
    } else if (!isAwaitingApprovalSeller) {
      const paid = status === InvoiceStatus?.PAID;
      const approved = status === InvoiceStatus.APPROVED;
      const draft = status === InvoiceStatus.DRAFT;
      const seller_Approved =
        status === InvoiceStatus?.SELLER_INTERNAL_APPROVED ||
        InvoiceStatus?.AWAITING_APPROVAL_BUYER;
      if (draft) {
        return {
          bg: 'bg-[#EBEBEBE5]',
          icon: 'black',
          border: 'after:border-[#EBEBEBE5]',
        };
      }
      if (paid) {
        if (
          currentStatus === InvoiceStatus?.APPROVED_FOR_FUNDING ||
          currentStatus === InvoiceStatus?.PAID
        ) {
          return {
            bg: 'bg-[#0353A4]',
            icon: 'white',
            border: 'after:border-[#0353A4]',
          };
        }
      }
      if (approved && currentStatus === InvoiceStatus.APPROVED_FOR_FUNDING) {
        return {
          bg: 'bg-[#0353A4]',
          icon: 'white',
          border: 'after:border-[#0353A4]',
        };
      }
      if (seller_Approved) {
        if (
          currentStatus === InvoiceStatus?.AWAITING_APPROVAL_SELLER ||
          currentStatus === InvoiceStatus?.AWAITING_APPROVAL_SELLER ||
          currentStatus === InvoiceStatus?.SELLER_INTERNAL_APPROVED ||
          currentStatus === InvoiceStatus?.AWAITING_APPROVAL_BUYER ||
          currentStatus === InvoiceStatus?.APPROVED
        ) {
          return {
            bg: 'bg-[#0353A4]',
            icon: 'white',
            border: 'after:border-[#0353A4]',
          };
        }
      }
    }

    // Default case
    return {
      bg: 'bg-[#EBEBEBE5]',
      icon: 'black',
      border: 'after:border-[#EBEBEBE5]',
    };
  };

  const handleApprove = () => {
    setIsSubmitting(true);
    approveInvoice({ businessId: docs[0]?.businessID?._id, invoiceId: invoice?._id });
  };

  const handleDecline = (reason: any) => {
    setIsSubmitting(true);
    declineInvoice({
      businessId: docs[0]?.businessID?._id,
      invoiceId: invoice?._id,
      payload: {
        note: reason,
      },
    });
  };

  useEffect(() => {
    if (isApproveInvoiceSuccess && !isApproveInvoiceError) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setIsSubmitting(false);
      getInvoice();
    } else if (isApproveInvoiceError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveInvoiceError, isApproveInvoiceSuccess]);

  useEffect(() => {
    if (isDeclineInvoiceSuccess && !isDeclineInvoiceError) {
      setShowDeclineModal(false);
      setIsSubmitting(false);
      toast.success('You have successfully decline this invoice.');
      getInvoice();
    } else if (isDeclineInvoiceError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeclineInvoiceError, isDeclineInvoiceSuccess]);

  const currentStatus: string =
    invoice?.status === InvoiceStatus?.SELLER_INTERNAL_APPROVED
      ? InvoiceStatus?.SELLER_INTERNAL_APPROVED
      : InvoiceStatus.AWAITING_APPROVAL_BUYER;

  const compare: string =
    docs[0].businessID?._id === invoice?.sellerID?._id
      ? currentStatus
      : InvoiceStatus.AWAITING_APPROVAL_SELLER;
  const checkForApproval: boolean = invoice?.status === compare;

  const getSellerLevel = groupSellerAndBuyerByLevel(invoice?.sellerApprovedBy || []);

  const getBuyerLevel = groupSellerAndBuyerByLevel(invoice?.buyerApprovedBy || []);

  return (
    <div>
      {/* Status */}
      <div className=" max-[980px]:ml-0 min-[900px]:px-4 max-[900px]:px-0 my-10 w-full">
        <ol className="flex items-center w-full h-[150px] max-[900px]:overflow-x-auto ">
          {/* <div className='w-full flex flex-col justify-center gap-y-4'>
            <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#EBEBEBE5] after:border-4 after:inline-block">
              <span className="flex items-center justify-center w-12 h-12 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                <Icon name={Icons.Sent} />
              </span>
            </li>
            <span className='w-[5rem] h-10'>Sent for approval</span>
          </div> */}
          <div className="min-[900px]:ml-10 w-full min-[900px]:min-w-[120px] max-[900px]:min-w-[150px] flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).border
              } after:border-4 after:inline-block transition-all duration-700 ease-in-out`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0 transition-all duration-700 ease-in-out`}
              >
                <Icon
                  name={Icons.Awaiting}
                  fill={getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).icon}
                />
              </span>
            </li>
            <span className="w-[7rem] h-10">
              {docs[0].businessID?._id === invoice?.sellerID?._id
                ? 'Awaiting sellers approval'
                : 'Awaiting internal approval'}
            </span>
          </div>
          <div className="w-full max-[900px]:min-w-[180px] flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.APPROVED).border
              } after:border-4 after:inline-block  transition-all duration-700 ease-in-out`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.APPROVED).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0  transition-all duration-700 ease-in-out`}
              >
                <Icon
                  name={Icons.Approve}
                  fill={getBackgroundColor(InvoiceStatus.APPROVED).icon}
                />
              </span>
            </li>
            <span className="min-[900px]:w-[5rem] max-[900px]:w-full  max-[900px]:text-left min-[900px]:text-center max-[900px]:ml-0 min-[900px]:-ml-4 h-10">
              Invoice approved
            </span>
          </div>
          {docs[0]?.businessID?._id === invoice?.sellerID?._id ? (
            ''
          ) : (
            <div className="w-full max-[900px]:min-w-[180px] flex flex-col justify-center gap-y-4">
              <li
                className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                  getBackgroundColor(
                    InvoiceStatus?.SELLER_INTERNAL_APPROVED ||
                      InvoiceStatus.AWAITING_APPROVAL_BUYER
                  ).border
                } after:border-4 after:inline-block  transition-all duration-700 ease-in-out`}
              >
                <span
                  className={`flex items-center justify-center w-12 h-12 ${
                    getBackgroundColor(
                      InvoiceStatus?.SELLER_INTERNAL_APPROVED ||
                        InvoiceStatus.AWAITING_APPROVAL_BUYER
                    ).bg
                  } rounded-full lg:h-14 lg:w-14 shrink-0  transition-all duration-700 ease-in-out`}
                >
                  <Icon
                    name={Icons.Sent}
                    fill={
                      getBackgroundColor(
                        InvoiceStatus.AWAITING_APPROVAL_BUYER ||
                          InvoiceStatus?.SELLER_INTERNAL_APPROVED
                      ).icon
                    }
                  />
                </span>
              </li>
              <span className="w-[5rem] text-center -ml-4 h-10">Sent to customer</span>
            </div>
          )}
          <div className="w-full max-[900px]:min-w-[180px] flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(
                  InvoiceStatus.AWAITING_APPROVAL_BUYER ||
                    InvoiceStatus?.SELLER_INTERNAL_APPROVED
                ).border
              } after:border-4 after:inline-block  transition-all duration-700 ease-in-out`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(
                    InvoiceStatus.AWAITING_APPROVAL_BUYER ||
                      InvoiceStatus?.SELLER_INTERNAL_APPROVED
                  ).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0  transition-all duration-700 ease-in-out`}
              >
                <Icon
                  name={Icons.Awaiting}
                  fill={
                    getBackgroundColor(
                      InvoiceStatus.AWAITING_APPROVAL_BUYER ||
                        InvoiceStatus?.SELLER_INTERNAL_APPROVED
                    ).icon
                  }
                />
              </span>
            </li>
            <span className="w-[9rem] text-center -ml-9 h-10">
              {docs[0]?.businessID?._id === invoice?.sellerID?._id
                ? 'Awaiting internal approval'
                : 'Awaiting customers approval'}
            </span>
          </div>
          <div className="w-full max-[900px]:min-w-[180px] flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).border
              } after:border-4 after:inline-block  transition-all duration-700 ease-in-out`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0  transition-all duration-700 ease-in-out`}
              >
                <Icon
                  name={Icons.Payment}
                  fill={getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Awaiting payment</span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li className="flex w-full items-center">
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.PAID).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0  transition-all duration-700 ease-in-out`}
              >
                <Icon
                  name={Icons.Approve}
                  fill={getBackgroundColor(InvoiceStatus.PAID).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Payment completed</span>
          </div>
          {/* <div className='w-full'>
              <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#0353A4] after:border-4 after:inline-block">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Awaiting} />
                </span>
              </li>
              <span>Awaiting customer approval</span>
            </div>
            <div className='w-full'>
              <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#0353A4] after:border-4 after:inline-block">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Payment} />
                </span>
              </li>
              <span>Awaiting payment</span>
            </div>
            <div className='w-full'>
              <li className="flex w-full items-center ">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Approve} />
                </span>
              </li>
              <span>Payment completed</span>
            </div> */}
        </ol>
      </div>

      <div className="bg-[#B9D6F23D] rounded-2xl min-[900px]:p-10 max-[900px]:p-5 min-[900px]:mx-10 max-[900px]:mx-1 mb-5">
        <Fragment>
          {checkForApproval && (
            <div className="flex justify-between w-full flex-wrap gap-[30px]  items-center divide-x divide-black/10 max-[1400px]:flex-wrap max-[1400px]:justify-center max-[1400px]:divide-x-0 ">
              <div className="flex justify-between items-center flex-wrap gap-y-[30px] gap-x-16 max-[1400px]:flex-wrap max-[1400px]:gap-y-6">
                {Object.keys(
                  docs[0]?.businessID?._id === invoice?.sellerID?._id
                    ? groupedData?.payable
                    : groupedData.receivable
                ).map((level) => {
                  const checks =
                    docs[0]?.businessID?._id === invoice?.sellerID?._id
                      ? groupedData?.payable[level]
                      : groupedData.receivable[level].filter((res: any) =>
                          docs[0]?.businessID?._id === invoice?.sellerID?._id
                            ? res.approvals?.payable?.blocked === false
                            : res.approvals.receivable.blocked === false
                        );
                  return (
                    <Fragment key={level}>
                      <div className="flex flex-col gap-1">
                        {checks[0]?.firstName && (
                          <p className="text-black/50 font-inter font-medium text-sm">
                            Level {level}
                          </p>
                        )}
                        <p className="text-black font-inter font-medium text-xl">
                          {checks.length > 0
                            ? level === '1'
                              ? checks
                                  .map((res: any) => `${res.firstName} ${res.lastName}`)
                                  .join(', ')
                              : `${checks[0]?.firstName} ${checks[0]?.lastName}`
                            : ''}
                        </p>
                        {checks[0]?.firstName && (
                          <div>
                            {docs[0]?.businessID?._id === invoice?.sellerID?._id ? (
                              level <= invoice?.buyerApprovedBy.length.toString() ? (
                                <div className="whitespace-nowrap text-sm mt-2">
                                  <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                                  Approved
                                </div>
                              ) : (
                                <div className="whitespace-nowrap text-sm mt-2">
                                  <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#F48D14E8] rounded-full"></span>
                                  {currentUserApproval && (
                                    <span>
                                      {currentUserApproval?.businessID ===
                                        docs[0]?.businessID?._id &&
                                      level.match(currentUserApproval?.level)
                                        ? 'Awaiting your approval'
                                        : 'Awaiting approval'}{' '}
                                    </span>
                                  )}
                                </div>
                              )
                            ) : level <= `${invoice?.sellerApprovedBy?.length}` ? (
                              <div className="whitespace-nowrap text-sm mt-2">
                                <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                                Approved
                              </div>
                            ) : (
                              <div className="whitespace-nowrap text-sm mt-2">
                                <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#F48D14E8] rounded-full"></span>
                                {currentUserApproval?.businessID ===
                                  docs[0]?.businessID?._id &&
                                level.match(currentUserApproval?.level)
                                  ? 'Awaiting your approval'
                                  : 'Awaiting approval'}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              {docs[0]?.businessID?._id === invoice?.sellerID?._id ? (
                currentUserApproval && (
                  <div>
                    {Number(currentUserApproval?.level) >
                    (invoice?.buyerApprovedBy?.length ?? 0) + 1 ? (
                      ''
                    ) : Number(currentUserApproval?.level) ===
                      (invoice?.buyerApprovedBy?.length ?? 0) + 1 ? (
                      <div className="flex gap-6 items-center max-[1400px]:mt-[30px]">
                        <Button
                          type="button"
                          variant="custom"
                          size="custom"
                          label="Approve Invoice"
                          className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                          onClick={() => setShowConfirmModal(true)}
                        />
                        <Button
                          type="button"
                          variant="custom"
                          size="custom"
                          label="Decline Invoice"
                          className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                          onClick={() => setShowDeclineModal(true)}
                        />
                      </div>
                    ) : Number(currentUserApproval?.level) <
                      (invoice?.buyerApprovedBy?.length ?? 0) + 1 ? (
                      ''
                    ) : invoice?.buyerApprovedBy?.length === 0 &&
                      Number(currentUserApproval?.level) === 1 ? (
                      <div className="flex gap-6 items-center max-[1400px]:mt-[30px]">
                        <Button
                          type="button"
                          variant="custom"
                          size="custom"
                          label="Approve Invoice"
                          className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                          onClick={() => setShowConfirmModal(true)}
                        />
                        <Button
                          type="button"
                          variant="custom"
                          size="custom"
                          label="Decline Invoice"
                          className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                          onClick={() => setShowDeclineModal(true)}
                        />
                      </div>
                    ) : (
                      ''
                    )}{' '}
                  </div>
                )
              ) : currentUserApproval &&
                Number(currentUserApproval?.level) >
                  (invoice?.sellerApprovedBy?.length ?? 0) + 1 ? (
                ''
              ) : Number(currentUserApproval?.level) ===
                (invoice?.sellerApprovedBy?.length ?? 0) + 1 ? (
                <div className="flex gap-6 items-center max-[1400px]:mt-[30px]">
                  <Button
                    type="button"
                    variant="custom"
                    size="custom"
                    label="Approve Invoice"
                    className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                    onClick={() => setShowConfirmModal(true)}
                  />
                  <Button
                    type="button"
                    variant="custom"
                    size="custom"
                    label="Decline Invoice"
                    className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                    onClick={() => setShowDeclineModal(true)}
                  />
                </div>
              ) : Number(currentUserApproval?.level) <
                (invoice?.sellerApprovedBy?.length ?? 0) + 1 ? (
                ''
              ) : invoice?.sellerApprovedBy?.length === 0 &&
                Number(currentUserApproval?.level) === 1 ? (
                <div className="flex gap-6 items-center max-[1400px]:mt-[30px]">
                  <Button
                    type="button"
                    variant="custom"
                    size="custom"
                    label="Approve Invoice"
                    className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                    onClick={() => setShowConfirmModal(true)}
                  />
                  <Button
                    type="button"
                    variant="custom"
                    size="custom"
                    label="Decline Invoice"
                    className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                    onClick={() => setShowDeclineModal(true)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </Fragment>

        {invoice?.status === 'approved_for_financing' && (
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-1">
              <p className="text-black/50 font-inter font-medium text-sm">Amount Paid</p>
              <p className="text-black font-inter font-medium text-xl">₦170,000.00</p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-black/50 font-inter font-medium text-sm">
                Outstanding Amount{' '}
              </p>
              <p className="text-black font-inter font-medium text-xl">₦100,000.00</p>
            </div>
            <div className="border-r border-black/10 max-h-20 h-12" />
            <div className="pl-10">
              {/* <div className='flex flex-col gap-1 w-2/3'>
                <p className='text-black font-inter font-medium text-sm'>Favourite promise has made payment of ₦100,000.00</p>
                <span onClick={() => setShowPaymentProofModal(true)} className='cursor-pointer text-[#0353A4] font-inter font-medium text-sm'>View Proof of Payment</span>
              </div> */}
              {invoice?.status === 'approved_for_financing' && (
                <Button
                  type="button"
                  variant="primary"
                  size="custom"
                  label="Send Reminder"
                  className="px-5 py-2.5 text-sm text-white"
                  onClick={() => setShowReminderModal(true)}
                />
              )}
            </div>
          </div>
        )}

        <div className="flex items-start flex-wrap">
          {sellerApprovalNextLevel === 'null' && (
            <div className="flex items-center gap-[50px]">
              {docs[0]?.businessID?._id === invoice?.businessID?._id &&
                getSellerLevel.map((user) => (
                  <div key={user?.id} className="">
                    <div>
                      <span className="text-black/50 font-inter font-medium text-sm">
                        Level {user.receivableLevel}{' '}
                      </span>
                      <h2 className="text-black font-inter font-medium text-xl">
                        {user?.name}{' '}
                      </h2>
                      <div className="whitespace-nowrap text-sm mt-3">
                        <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                        Approved
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {buyerApprovalNextLevel === 'null' &&
            docs[0]?.businessID?._id === invoice?.sellerID?._id && (
              <div className="flex items-center gap-[50px] flex-wrap">
                {getBuyerLevel.map((user) => (
                  <div key={user?.id}>
                    <span className="text-black/50 font-inter font-medium text-sm">
                      Level{user?.payableLevel}
                    </span>
                    <h2 className="text-black font-inter font-medium text-xl">
                      {user?.name}{' '}
                    </h2>
                    <div className="whitespace-nowrap text-sm mt-3">
                      <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                      Approved
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>

      {showDeclineModal && (
        <EnhancedDeclineModal
          onCancel={() => setShowDeclineModal(false)}
          onSuccess={(values: any) => handleDecline(values)}
          isSubmitting={isSubmitting}
        />
      )}

      {showConfirmModal && (
        <EnhancedConfirmModal
          content={'Are you sure you want to approve this invoice ?'}
          onConfirm={() => {
            handleApprove();
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
          isSubmitting={isSubmitting}
        />
      )}

      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Invoice Approved"
          description="You have approved this invoice "
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {showReminderModal && (
        <EnhancedSendReminderModal
          onCancel={() => setShowReminderModal(false)}
          onSuccess={() => {
            setShowReminderModal(false);
            setShowSuccessModalForReminder(true);
          }}
        />
      )}
      {showSuccessModalForReminder && (
        <EnhancedSuccessModal
          title="Reminder Sent"
          description="A reminder has been sent to Favorite Promise"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}

//Please don't touch this yet in case There's an issue
//2 current levels of approval
//So initially for a supplier using the fetch endpoint, For an unapproved Invoice, both the sellerApprovedBy and buyerApprovedBy field is an empty array(We're using sellerApprovedBy though since its the seller). - SellerApprovalNectLevel and BuyerApprovalNextLevel are both 1
//!Now I want to check if when i approve this invoice what happens
//*So after approval now the sellerApprovedBy contains the object of the  person that approved the invoice that's for level1, Now we cannot have more than two levels inside the sellerApprovalNextevel

///Now after completing approval for level 2 on the seller side for that invoice the sellerApprovalNextLevel has become null while the buyerApprovalNextLevel is 1 hence It has passed through all the approval workflow and the buyer should now be able to interfere with the invoice on their own workflow.
//!Now on the buyer side while the seller hasn't fully approved the invoice the sellerApprovalNextLevel is 2 while the buyerApprovalNextLevel is 1 nothings changed from the buyer side too.
//Approvals on New vendor
//!Estherokafor05+105@gmail.com first level
//*Estherokafor05+test1@gmail.com second level

//Mayor!!! Okay Think of it this way...
//! So first I need to know the level of the person viewing right?.
//Now That is dependent of the currentUserApproval Seeing somebody.
//Now if the person is found though.
//I get their current level and run a check.
//That check would be if the sellerApprovedBy.length + 1 === the level of the person currently viewing
//If that is true, then show the approval button
//If that isn't true, Then I don't show the button.

//todo: Example if the level of the person currently viewing that invoice is 10.
//? If the actual length of the sellerApprovedBy is 2 then it means only somebody with level 3 can approve next.
//*Therefore we say if the level of the person viewing it is > sellerApprovedBy.length + 1 then we do not show the button.
//! But if the level of the person viewing the invoice has a level === sellerApprovedBy.length + 1 Show the button
//? If the person viewing the invoice has less than the sellerApprovedBy.length do not show the button
//! Note that if the sellerApprovedBy is an [] and the level of the person is 1, we show the button
