import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../../Components/Form/TextField';
import Button from '../../../Components/Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  useCreateUserAccount,
  useVerifyToken,
} from '../../../hooks/queries-and-mutations/auth';
import { toast } from 'react-toastify';
import { CreateAccountDto } from '../../../types/user.type';
import { AnimatePresence, motion } from 'framer-motion';

export default function CreateAccount() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const { mutate, isSuccess, isError, isLoading } = useCreateUserAccount();

  const { data } = useVerifyToken({ token: token ?? '' });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z]+$/, 'First Name can only contain letters')
        .required('First Name is required')
        .nullable(),
      lastName: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Last Name can only contain letters')
        .required('Last Name is required')
        .nullable(),
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
      password: Yup.string()
        .required('Password is required')
        .nullable()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
          'Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long'
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm password is required')
        .nullable(),
    }),

    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const registeredEmail = useMemo(() => {
    if (data?.email) {
      formik.setFieldValue('email', data.email);
      return data.email;
    }
  }, [data]);

  const onSubmit = (createAccountDto: CreateAccountDto) => {
    const payLoad = {
      email: createAccountDto.email.toLocaleLowerCase(),
      password: createAccountDto.password,
      firstName: createAccountDto.firstName,
      lastName: createAccountDto.lastName,
    };
    mutate(payLoad);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success(
        'Check your mail a confirmation mail has been sent to verify and activate your User Account.'
      );
      navigate('/auth/login');
      formik.resetForm();
    }
    if (isError) {
      formik.setSubmitting(false);
    }
  }, [isError, isSuccess]);

  const [passwordStrength, setPasswordStrength] = useState<string>('');
  const [matched, setMatched] = useState<boolean>(false);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [missingCriteria, setMissingCriteria] = useState<string[]>([]);

  const validatePasswordStrength = (password: string) => {
    if (password.trim() === '') {
      setPasswordStrength('');
      setMissingCriteria([]);
      return;
    }

    const strongRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-!+@#$^&*]).{8,40}$/; // Include underscore
    const mediumRegex = /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/;

    const criteria: string[] = [];
    if (!/(?=.*?[A-Z])/.test(password)) criteria.push('an uppercase letter');
    if (!/(?=.*?[a-z])/.test(password)) criteria.push('a lowercase letter');
    if (!/(?=.*?[0-9])/.test(password)) criteria.push('a number');
    if (!/(?=.*?[-!+@#$^&*_])/.test(password)) criteria.push('a special character');
    if (password.length < 8) criteria.push('at least 8 characters long');

    setMissingCriteria(criteria);
    setTimeout(() => {
      setMissingCriteria([]);
    }, 7000);

    if (strongRegex.test(password)) {
      setPasswordStrength('Strong');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Weak');
    }

    setTimeout(() => {
      setPasswordStrength('');
    }, 5000);
  };

  const handlePasswordChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    setPasswordValue(e.target.value);
    validatePasswordStrength(e.target.value);
  };

  const handleConfirm = async (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    checkForMatch(passwordValue, confirmPassword);
  }, [passwordValue, confirmPassword]);

  const checkForMatch = async (password: string, confirmedPassword: string) => {
    if (password === confirmedPassword && confirmPassword.length > 1) {
      setMatched(true);
      setTimeout(() => {
        setMatched(false);
      }, 5000);
    } else {
      setMatched(false);
    }
  };

  return (
    <div className="w-[430px] md:px-0 px-9">
      <div className="flex flex-col items-center">
        <p className="text-3xl font-medium">Welcome to Paytton</p>
        <span className="text-sm font-normal">
          Please enter your detail below to start
        </span>
      </div>
      <div className="relative w-full max-w-[870px] mt-5">
        <div className="relative flex flex-col justify-center rounded-3xl bg-[#0353A41A] px-12 max-[400px]:!px-4 py-16">
          <div className="mx-auto w-full max-w-[440px] max-[400px]:max-w-full">
            <form className="space-y-1" onSubmit={formik.handleSubmit}>
              <TextField
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="First Name"
                error={formik.touched.firstName ? formik.errors.firstName : ''}
              />
              <TextField
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Last Name"
                error={formik.touched.lastName ? formik.errors.lastName : ''}
              />
              <TextField
                name="email"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                disabled={!!registeredEmail}
                label="Email"
                error={formik.touched.email ? formik.errors.email : ''}
              />
              <TextField
                name="password"
                type="password"
                value={formik.values.password}
                onChange={handlePasswordChange}
                onBlur={formik.handleChange}
                label="New Password"
                showPasswordToggler
                error={formik.touched.password ? formik.errors.password : ''}
                indicator={passwordStrength}
                missingCriteria={
                  missingCriteria.length > 0 ? missingCriteria.join(', ') : ''
                }
              />
              <TextField
                name="confirmPassword"
                type="password"
                value={formik.values.confirmPassword}
                onChange={handleConfirm}
                onBlur={formik.handleChange}
                label="Confirm password"
                showPasswordToggler
                error={
                  formik.touched.confirmPassword ? formik.errors.confirmPassword : ''
                }
              />
              <AnimatePresence>
                {matched && (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="mt-2 text-xs text-green-600"
                  >
                    {' '}
                    Perfect! passwords are a match.{' '}
                  </motion.p>
                )}
              </AnimatePresence>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label="Create Account"
                  className="mt-10"
                  loading={formik.isSubmitting || isLoading}
                  disabled={formik.isSubmitting}
                />
              </div>
            </form>
            <div className="text-center text-[#00000066] text-xs mt-4">
              Already have an account?
              <Link to="/auth/login" className="text-[#061A40] ml-1">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
