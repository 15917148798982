import logo from '../../../Assests/logo-dark.png';
import { InfoModal } from '../InfoModal';
import { TransactionDoc } from '../../../types/transaction.type';
import { format } from 'date-fns';
import { formatCurrency } from '../../../utils';
import TransactionPdf from '../../TransactionPdf';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  transaction: TransactionDoc;
};

export default function TransactionDetailModal({ onCancel, transaction }: Props) {
  return (
    <InfoModal
      width="min-[1100px]:w-[50%] max-[1100px]:w-[70%] max-[800px]:w-[90%] max-[540px]:w-[100%] min-w-[300px] "
      className="h-fit"
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white p-8 max-[640px]:p-0 overflow-x-hidden font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="flex items-center justify-between py-5 text-left">
            <img src={logo} className="max-[480px]:w-[100px]" alt="logo" />
            <p className="min-[480px]:text-xl max-[480px]:text-[16px] font-medium text-black font-inter">
              Transaction Detail
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <div className="flex flex-col items-center justify-center">
              <p className="text-sm font-medium text-black font-inter">
                Transaction Amount
              </p>
              <p className="text-2xl font-semibold text-black font-inter">
                {formatCurrency(
                  transaction?.currency === 'Dollars' ? 'usd' : transaction?.currency,
                  transaction?.amount
                )}
              </p>
            </div>
            <div className="pl-4 max-[540px]:pl-0 mt-9">
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Vendor</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.vendorName}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Invoice number</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.invoiceNumber}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Date and time</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.createdAt
                    ? `${format(
                        new Date(transaction?.createdAt),
                        'dd, MMM yyyy'
                      )} | ${format(new Date(transaction?.createdAt), 'HH:mm:ss')}`
                    : 'N/A'}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Description</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.paidAt
                    ? format(new Date(transaction?.paidAt), 'dd, MMM yyyy')
                    : ''}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">
                  Transaction reference
                </span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.id}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Payment type</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction?.paymentType}
                </span>
              </div>
            </div>

            <TransactionPdf transaction={transaction} />
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
