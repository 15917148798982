import { useMemo, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../../Components/Icon';
import TextField from '../../../../../Components/Form/TextField';
import { BusinessId, ContactDto } from '../../../../../types/business.type';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

type Props = {
  onSave: (values: ContactDto) => void;
  business: BusinessId;
};

export default function EditCompanyAddress({ onSave, business }: Props) {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');

  const addresses = useMemo(() => {
    if (business?.addresses?.length) {
      return business.addresses.map((ad) => ({
        address: ad.address ?? '',
        country: ad.country ?? '',
        state: ad.state ?? '',
        postalCode: ad.postalCode ?? '',
        addressType: ad.addressType ?? '',
      }));
    } else {
      return [
        {
          address: '',
          country: '',
          state: '',
          postalCode: '',
          addressType: '',
        },
      ];
    }
  }, [business?.addresses]);

  const formik = useFormik({
    initialValues: {
      type: business.type,
      name: business?.name ?? '',
      email: business?.email ?? '',
      phone: business?.phone ?? '',
      rcNumber: business?.rcNumber ?? '',
      industryType: business?.industryType ?? '',
      companySize: business?.companySize ?? '',
      companyAddresss: addresses,
    },
    validationSchema: Yup.object({
      companyAddresss: Yup.array().of(
        Yup.object().shape({
          address: Yup.string().required('Company address is required').nullable(),
          country: Yup.string().required('Country is required').nullable(),
          state: Yup.string().required('State is required').nullable(),
          postalCode: Yup.string().required('Postal code is required').nullable(),
          addressType: Yup.string().required('Address type is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values as ContactDto);
    },
  });

  const addMoreAddress = () => {
    const newAddresses = [
      ...formik.values.companyAddresss,
      {
        address: '',
        country: '',
        state: '',
        postalCode: '',
        addressType: '',
      },
    ];
    formik.setFieldValue('companyAddresss', newAddresses);
  };

  useEffect(() => {
    setSelectedCountry('');
    setSelectedState('');
  }, [business]);

  return (
    <div>
      <form className="gap-4 mt-10" onSubmit={formik.handleSubmit}>
        {formik.values.companyAddresss.map((ad, index) => (
          <div key={index} className="mt-10 lg:mt-4 md:mt-4">
            <TextField
              name={`companyAddresss[${index}].address`}
              type="text"
              value={ad.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Company Address"
              error={
                formik.touched.companyAddresss
                  ? formik.errors.companyAddresss?.[index] &&
                    formik.errors.companyAddresss.length > 0
                    ? JSON.parse(JSON.stringify(formik.errors.companyAddresss[index]))
                        .address
                    : ''
                  : ''
              }
              className="bg-[#FFFFFF8C]"
            />
            <div className="grid flex-1 items-center grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
              <div className="h-10 border border-gray-400 rounded-md">
                <div className="mt-2">
                  <CountryDropdown
                    value={selectedCountry || ad.country}
                    onChange={(val) => {
                      setSelectedCountry(val);
                      setSelectedState('');
                      formik.setFieldValue(`companyAddresss[${index}].country`, val);
                    }}
                    classes="w-full outline-none border-none cursor-pointer"
                  />
                </div>
              </div>

              <div className="h-10 border border-gray-400 rounded-md">
                <div className="flex items-center justify-center mt-2 outline-none">
                  <RegionDropdown
                    country={selectedCountry || ad.country}
                    value={selectedState || ad.state}
                    onChange={(val) => {
                      setSelectedState(val);
                      formik.setFieldValue(`companyAddresss[${index}].state`, val);
                    }}
                    classes="w-full outline-none border-none cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="grid flex-1 grid-cols-1 mt-8 gap-x-14 gap-y-2 sm:grid-cols-2">
              <div>
                <select
                  name={`companyAddresss[${index}].addressType`}
                  value={ad.addressType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="h-[2.7rem] rounded-lg w-full bg-[#FFFFFF8C] border border-gray-400"
                >
                  <option value="" disabled>
                    Select Address Type
                  </option>
                  <option value="operating">Operating Address</option>
                  <option value="resident">Resident Address</option>
                </select>
              </div>

              <div>
                <TextField
                  name={`companyAddresss[${index}].postalCode`}
                  type="text"
                  value={ad.postalCode}
                  onChange={(event) => {
                    const newValue = event.target.value.replace(/[^0-9]/g, '');
                    formik.handleChange(event);
                    formik.setFieldValue(
                      `companyAddresss[${index}].postalCode`,
                      newValue
                    );
                  }}
                  onBlur={formik.handleBlur}
                  label="Postal Code"
                  error={
                    formik.touched.companyAddresss
                      ? formik.errors.companyAddresss?.[index] &&
                        formik.errors.companyAddresss.length > 0
                        ? JSON.parse(JSON.stringify(formik.errors.companyAddresss[index]))
                            .postalCode
                        : ''
                      : ''
                  }
                  className="bg-[#FFFFFF8C]"
                />
              </div>
            </div>
          </div>
        ))}
        <div
          role="button"
          className="flex items-center mt-5"
          tabIndex={-1}
          onClick={addMoreAddress}
        >
          <div className="flex items-center gap-1">
            <Icon name={Icons.Add} />
            <span className="text-sm min-w-[150px] font-medium text-[#000000]">
              Add another address
            </span>
          </div>
          <div className="ml-1 line" />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="md"
          label="Save & Continue"
          className="mt-12"
        />
      </form>
    </div>
  );
}
