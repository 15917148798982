import React, { useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import SearchInput from '../../../../Components/Form/SearchInput';
import { Icons } from '../../../../Components/Icon';
import EmptyRecord from '../../../../Components/EmptyRecord';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useAppSelector } from '../../../../store/hooks';
import { useGetAudits } from '../../../../hooks/queries-and-mutations/audit';
import { AuditDoc } from '../../../../types/audit.type';
import Loader from '../../../../Assests/loader.gif';
import { format } from 'date-fns';

export default function AuditTrail() {
  const { docs } = useAppSelector(selectBusiness);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<AuditDoc[] | null>(null);

  const { data, isLoading } = useGetAudits({ businessId: docs[0].businessID._id });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = data?.docs.filter(
      (item: AuditDoc) =>
        item.collaborator.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item?.role ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData as AuditDoc[]);
  };

  return (
    <div>
      <Breadcrumb text="Audit Log" />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <div className="w-1/3">
            <SearchInput
              value={search}
              placeholder="Search"
              name={''}
              className="h-10"
              onChange={(e) => handleSearch(e)}
              style="max-sm:flex sm:flex min-w-[200px]"
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="relative overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
            <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
              <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                <tr>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Time
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Collaborator
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Role
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Category
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filteredData || data?.docs)?.map((item) => (
                  <tr key={item._id} className="bg-white/60">
                    <th
                      scope="row"
                      className="p-5 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {format(new Date(item.createdAt), 'dd MMM yyyy')}
                    </th>
                    <td className="px-6 py-6 text-center min-w-[200px]">
                      {format(new Date(item.createdAt), 'HH:mm:ss')}
                    </td>
                    <td className="px-6 py-6 text-center min-w-[200px]">
                      {item?.collaborator ?? ''}
                    </td>
                    <td className="px-6 py-6 text-center min-w-[150px]">
                      {item?.role ?? ''}
                    </td>
                    <td className="px-6 py-6 text-center min-w-[200px]">
                      {item?.category ?? ''}
                    </td>
                    <td className="px-6 py-6 text-center min-w-[250px]">
                      {item?.action ?? ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {!(filteredData || data?.docs || [])?.length && (
              <EmptyRecord
                title="No audit to show yet"
                content="You’ve got a  blank state. We’ll let you know when new audit arrive"
                icon={Icons.Audit}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
