import { useRef } from 'react';
import useOnClickOutside from '../../CustomHooks/useClickOutside';

type IProps = {
  applyFilter: (val: string) => void;
  options?: { value: string; label: string }[];
  activeIndicator?: string;
  setIsSortDropdownVisible: (arg: boolean) => void;
  all?: string;
  dropPosition?: string;
};

export default function FilterMoreOption({
  applyFilter,
  options,
  activeIndicator,
  setIsSortDropdownVisible,
  dropPosition,
}: IProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const close = () => {
    setIsSortDropdownVisible(false);
  };
  useOnClickOutside(modalRef, close);

  return (
    <div
      ref={modalRef}
      className={`absolute min-[540px]:right-0 top-[40px] ${dropPosition} w-32 bg-white shadow-xl`}
    >
      <div className="flex flex-col justify-between">
        <div className="flex flex-col py-3">
          <p
            className="flex justify-between items-center p-2 pt-2 text-sm cursor-pointer hover:bg-[#0353A40F]"
            onClick={() => {
              if (activeIndicator === 'all') {
                applyFilter('all');
              } else {
                applyFilter('');
              }
            }}
          >
            <span>All</span>{' '}
            {activeIndicator === 'all' && (
              <span className="w-[8px] h-[4px] p-1 bg-green-500 rounded-[50%]"></span>
            )}
          </p>
          <div className="mt-2 border-b border-[#D9D9D9]" />
        </div>
        {(options || []).map(({ value, label }) => {
          return (
            <p
              key={value}
              className="flex items-center justify-between gap-2 p-2 py-4 text-sm cursor-pointer hover:bg-[#0353A40F] transition duration-500 ease-out"
              onClick={() => {
                applyFilter(value);
              }}
            >
              <span>{label}</span>
              {activeIndicator === value && (
                <span className="w-[8px] h-[4px] p-1 bg-green-500 rounded-[50%]"></span>
              )}
            </p>
          );
        })}
      </div>
    </div>
  );
}
