import React, { useState } from 'react';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { useAppSelector } from '../../../../../store/hooks';
import PlansCard from './PlansCard';
import { Plan as PlanType } from '../../../../../types/subscription.type';
import { useGetPlans } from '../../../../../hooks/queries-and-mutations/subscription';
import { formatCurrency } from '../../../../../utils';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import PaymentModal from '../../../../../Components/Modal/PaymentModal';
import Button from '../../../../../Components/Form/Button';

const EnhancedPaymentModal = withCreatePortal(PaymentModal);

type IProp = {
  setShowPlan: (arg: boolean) => void;
};

const AllPlans: React.FC<IProp> = ({ setShowPlan }) => {
  const { docs } = useAppSelector(selectBusiness);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanType | null>(null);

  const { data } = useGetPlans();
  return (
    <div>
      <div
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          transition: 'all 0.2s ease-in-out',
        }}
        className="grid w-full items-start justify-between gap-2"
      >
        {(data ?? []).map((plan) => (
          <div key={plan?._id} className="group">
            <PlansCard
              level={plan.name}
              currency={formatCurrency(plan?.currency, plan?.price_per_seat)}
              benefits={plan?.features ?? []}
              time="month"
              onClick={() => {
                setSelectedPlan(plan);
                setShowPaymentModal(true);
              }}
            />
          </div>
        ))}
        {showPaymentModal && (
          <EnhancedPaymentModal
            onCancel={() => setShowPaymentModal(false)}
            onSuccess={() => setShowPaymentModal(false)}
            plan={selectedPlan}
            email={docs[0].businessID.email}
            businessId={docs[0].businessID._id}
          />
        )}
      </div>
      <div className="w-full mx-auto">
        <Button
          type="button"
          variant="custom"
          onClick={() => {
            setShowPlan(false);
          }}
          size="custom"
          label="Close Plans"
          className=" mt-[30px] mx-auto py-2.5 px-4 bg-[#0353A4] text-white "
        />
      </div>
    </div>
  );
};

export default AllPlans;
