import Button from '../Form/Button';
import { Icon, Icons } from '../Icon';
import { InfoModal } from './InfoModal';

type Props = {
  title: string;
  description: string;
  onCancel: () => void;
  cancelText: string;
  isDraft?: boolean;
};

export default function SuccessModal({
  title,
  description,
  onCancel,
  cancelText,
  isDraft,
}: Props) {
  return (
    <InfoModal
      width="w-[25%] min-w-[400px] max-[500px]:min-w-[250px] max-[500px]:w-[90%] "
      className="h-fit "
      onClose={onCancel}
    >
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          {isDraft ? <Icon name={Icons.Draft} /> : <Icon name={Icons.Success} />}
          <div className="max-w-xs py-5 min-[500px]:px-5 px-0 text-center">
            <p className="text-xl font-medium text-black font-inter">{title}</p>
            <p className="text-sm font-normal text-black font-inter">{description}</p>
          </div>
          <div className="flex items-center w-full">
            <Button
              variant="primary"
              size="lg"
              className="mb-3 w-[100rem] rounded px-[1.5rem] py-1 text-sm font-normal"
              label={cancelText}
              onClick={onCancel}
            ></Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
