import React, { useEffect, useState } from 'react';
import { InfoModal } from '../InfoModal';
import Button from '../../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useBlacklistVendor } from '../../../hooks/queries-and-mutations/vendor';
import { toast } from 'react-toastify';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  setBlackListedReason: React.Dispatch<React.SetStateAction<string>>;
  isAreadyBlackList: boolean;
  businessId: string;
  vendorId: string;
};

export default function BlacklistModal({
  onCancel,
  onSuccess,
  isAreadyBlackList,
  businessId,
  vendorId,
  setBlackListedReason,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate, isSuccess, isError } = useBlacklistVendor();

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: Yup.object({
      note: Yup.string().required('Note is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      mutate({
        businessId,
        vendorId,
        payload: {
          blacklisted: !isAreadyBlackList,
          blacklistedReason: values.note,
        },
      });
      setBlackListedReason(values.note);
    },
  });

  useEffect(() => {
    if (isSuccess && !isError) {
      if (!isAreadyBlackList) {
        toast.success('Vendor blacklisted successfully');
      } else {
        toast.success('Vendor removed from blacklist successfully');
      }
      onCancel();
      onSuccess();
      setIsSubmitting(false);
      formik.resetForm();
    } else if (isError) {
      setIsSubmitting(false);
    }
  }, [isSuccess, isError]);

  return (
    <InfoModal
      width="min-[1100px]:w-[45%] max-[1100px]:w-[60%] min-w-[500px] max-[650px]:min-w-[300px] max-[650px]:w-[90%]"
      className="h-fit "
      onClose={onCancel}
    >
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col justify-between">
          <div className="py-5 pb-3 mb-2 border-b">
            <p className="text-xl font-medium text-black font-inter">
              {isAreadyBlackList ? 'Remove Vendor from blacklist' : 'Blacklist'}
            </p>
            <p className="text-base font-normal font-inter text-black/40">
              Are you sure you want to{' '}
              {`${
                isAreadyBlackList
                  ? 'remove this vendor from blacklist?'
                  : 'blacklist this vendor?'
              } `}
            </p>
          </div>

          <form className="w-full pt-6 mt-5" onSubmit={formik.handleSubmit}>
            <label className="font-medium font-inter text-sm text-[#000000]">
              Tell us why you want to{' '}
              {`${
                isAreadyBlackList
                  ? 'remove this vendor from blacklist?'
                  : 'blacklist this vendor'
              }`}{' '}
            </label>
            <textarea
              name="note"
              rows={5}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              placeholder={`Tell us why you want to ${
                isAreadyBlackList
                  ? 'remove this vendor from blacklist?'
                  : 'blacklist this vendor'
              } `}
              className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.note && formik.errors.note
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
              defaultValue={formik.values.note}
            />
            {formik.errors.note && (
              <p className="mt-2 text-xs text-red-600 capitalize">{formik.errors.note}</p>
            )}
            <div className="flex max-[480px]:flex-col-reverse items-center justify-center w-full min-[480px]:gap-5 mt-5">
              <Button
                type="button"
                variant="outline"
                size="md"
                label="Cancel"
                className="my-5 px-5 py-2.5 text-sm text-[#061A40] bg-transparent"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
                onClick={onCancel}
              />
              <Button
                type="submit"
                variant="custom"
                size="md"
                label={isAreadyBlackList ? 'Remove Blacklist' : 'Blacklist vendor'}
                className="my-5 px-5 py-2.5 text-sm text-white bg-[#AA1414]"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </InfoModal>
  );
}
