import { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../Components/Icon';
import Button from '../../../../Components/Form/Button';
import TextField from '../../../../Components/Form/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ChangeProfileModal from '../../../../Components/Modal/ChangeProfileModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectBusiness,
  selectPermission,
} from '../../../../selectors/business-selector';
import { useResetPassword } from '../../../../hooks/queries-and-mutations/auth';
import { toast } from 'react-toastify';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import Avatar from '../../../../Assests/avatar-1.png';

const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedErrorModal = withCreatePortal(ErrorModal);
const EnhancedChangeProfileModal = withCreatePortal(ChangeProfileModal);

export default function Profile() {
  const [showEdit, setShowEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const { docs } = useAppSelector(selectBusiness);
  const { permissions } = useAppSelector(selectPermission);
  const { mutate, isSuccess, isError } = useResetPassword();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .nullable()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
          'Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long'
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm password is required')
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const payload = {
        oldPassword: values.password,
        newPassword: values.confirmPassword,
      };
      mutate(payload);
    },
  });

  const formikPersonal = useFormik({
    initialValues: {
      firstName: docs[0]?.firstName ?? '',
      lastName: docs[0]?.lastName ?? '',
      email: docs[0]?.email ?? '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required').nullable(),
      lastName: Yup.string().required('Last Name is required').nullable(),
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
    }),
    onSubmit: () => {
      setIsSubmitting(true);
      try {
        // dispatch(login({ email: email.trim(), password: password.trim() }));
      } catch (error) {
        console.log('Error', error);
      }
    },
  });

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('Password successfully changed');
      setIsSubmitting(false);
      formik.setSubmitting(false);
      setShowEdit(false);
    } else if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
      setShowEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  const handleProfileImageUpdate = (newImageURL: string) => {
    setProfileImage(newImageURL);
  };

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };
  return (
    <div className="panel rounded-[20px] bg-white p-10 px-12">
      <div className="pb-3 border-b border-black/10">
        <p className="text-2xl font-semibold text-black font-inter">Profile</p>
      </div>
      <div className="flex items-center border-b my-9 pb-9">
        <div className="relative flex-shrink-0 rounded-full">
          {profileImage || docs[0]?.profileImageURL ? (
            <img
              src={
                profileImage
                  ? profileImage
                  : docs[0]?.profileImageURL
                  ? docs[0]?.profileImageURL
                  : Avatar
              }
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = Avatar;
              }}
              alt="avatar"
              className="object-cover w-24 h-24 rounded-full"
            />
          ) : (
            <div className="flex items-center justify-center w-24 h-24 text-4xl text-black bg-gray-200 rounded-full">
              {getInitials(docs[0]?.firstName)}
            </div>
          )}
          <div
            role="button"
            tabIndex={0}
            className="absolute bottom-0 right-0 cursor-pointer"
            onClick={() => setShowUpload(true)}
          >
            <Icon name={Icons.EditCricle} />
          </div>
        </div>
        <div className="mx-3 text-left">
          <p className="mb-1 font-semibold">{docs[0]?.businessName}</p>
          <p className="max-w-[185px] truncate text-xs text-white-dark">
            Joined {new Date(docs[0]?.businessID.createdAt).toDateString()}
          </p>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between mb-[30px]">
          <span className="text-xl font-semibold text-black font-inter">
            Personal Information
          </span>
          {!showEdit && (
            <Button
              type="button"
              variant="outline"
              size="sm"
              label="Edit"
              className="bg-transparent"
              icon={<Icon name={Icons.EditPencil} />}
              iconPosition="left"
              iconLabelSpacing={5}
              onClick={() => setShowEdit(true)}
            />
          )}
        </div>

        <div className="border-b pb-9">
          {showEdit ? (
            <form onSubmit={formikPersonal.handleSubmit} className="mt-8">
              <div className="grid grid-cols-1 gap-6 mb-5 sm:grid-cols-2">
                <div>
                  <TextField
                    name="firstName"
                    type="text"
                    value={formikPersonal.values.firstName}
                    onChange={formikPersonal.handleChange}
                    onBlur={formikPersonal.handleChange}
                    label="First Name"
                    error={
                      formikPersonal.touched.firstName
                        ? formikPersonal.errors.firstName
                        : ''
                    }
                  />
                </div>
                <div>
                  <TextField
                    name="lastName"
                    type="text"
                    value={formikPersonal.values.lastName}
                    onChange={formikPersonal.handleChange}
                    onBlur={formikPersonal.handleChange}
                    label="Last Name"
                    error={
                      formikPersonal.touched.lastName
                        ? formikPersonal.errors.lastName
                        : ''
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 mb-5 sm:grid-cols-2">
                <div>
                  <TextField
                    name="email"
                    type="email"
                    value={formikPersonal.values.email}
                    onChange={formikPersonal.handleChange}
                    onBlur={formikPersonal.handleChange}
                    label="Email"
                    error={
                      formikPersonal.touched.email ? formikPersonal.errors.email : ''
                    }
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    label="Save Changes"
                    className="mt-6"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => setShowEdit(false)}
                  />
                </div>
              </div>
            </form>
          ) : (
            <div>
              <div className="grid max-w-lg grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-black/40 font-inter">
                    First Name
                  </span>
                  <span className="text-lg font-medium text-black font-inter">
                    {docs[0]?.firstName ?? 'N/A'}
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-black/40 font-inter">
                    Last Name
                  </span>
                  <span className="text-lg font-medium text-black font-inter">
                    {docs[0]?.lastName ?? 'N/A'}
                  </span>
                </div>
              </div>
              <div className="grid max-w-lg grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-black/40 font-inter">
                    Email
                  </span>
                  <span className="text-lg font-medium text-black font-inter">
                    {docs[0]?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-black/40 font-inter">
                    Role
                  </span>
                  <span className="text-lg font-medium text-black font-inter">
                    {docs[0]?.role ?? 'N/A'}
                  </span>
                </div>
              </div>
              <div className="grid max-w-lg grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-medium text-black/40 font-inter">
                    Permissions
                  </span>
                  <span className="text-lg font-medium text-black font-inter">
                    {permissions
                      .map((permission: string) => permission.replace(/_/g, ' '))
                      .join(', ')}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <form onSubmit={formik.handleSubmit} className="mt-8">
          <span className="text-xl font-semibold text-black font-inter mb-9">
            Reset Password
          </span>
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
              <div>
                <TextField
                  name="oldPassword"
                  type="password"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Old Password"
                  showPasswordToggler
                  error={formik.touched.oldPassword ? formik.errors.oldPassword : ''}
                />
              </div>
              <div>
                <TextField
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="New Password"
                  showPasswordToggler
                  error={formik.touched.password ? formik.errors.password : ''}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
              <div>
                <TextField
                  name="confirmPassword"
                  type="password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Confirm New Password"
                  showPasswordToggler
                  error={
                    formik.touched.confirmPassword ? formik.errors.confirmPassword : ''
                  }
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  label="Save Changes"
                  className="mt-6"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {showUpload && (
        <EnhancedChangeProfileModal
          onSuccess={(isSuccess: boolean, newImageURL: string) => {
            if (isSuccess) {
              handleProfileImageUpdate(newImageURL);
              setShowSuccessModal(true);
              setShowUpload(false);
            } else {
              setShowErrorModal(true);
              setShowUpload(false);
            }
          }}
          onCancel={() => setShowUpload(false)}
          businessId={docs[0]?.businessID._id}
        />
      )}
      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Image updated successfully"
          description="Your profile picture has been updated successfully"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
      {showErrorModal && (
        <EnhancedErrorModal
          title="Image update failed"
          description="Your profile picture could not be uploaded"
          cancelText="Try again"
          onCancel={() => {
            setShowErrorModal(false);
            setShowUpload(true);
          }}
        />
      )}
    </div>
  );
}
