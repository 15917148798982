import { useCallback, useEffect, useState } from 'react';
import { Icon, Icons } from '../Icon';
import Button from '../Form/Button';
import { useDropzone } from 'react-dropzone';
import { InfoModal } from './InfoModal';
import { useUpdateBusinessLogo } from '../../hooks/queries-and-mutations/business';

type Props = {
  onCancel: () => void;
  onSuccess: (isSuccess: boolean) => void;
  businessId: string;
};

export default function ChangeProfileModal({ onCancel, businessId, onSuccess }: Props) {
  const { mutate, isSuccess, isError } = useUpdateBusinessLogo();

  const [base64ImageSrc, setBase64ImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [ImageSrc, setImageSrc] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createImageSource = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64ImageSrc(reader.result);
      setImageSrc(file);
    };
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return null;
    }
    const file = acceptedFiles[0];
    // const fileType = file.type.split('/')[1];
    createImageSource(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1,
  });

  //useUpdateBusinessLogo

  const handleSubmit = () => {
    setIsSubmitting(true);
    const pictureData: any = {
      image: ImageSrc,
    };
    const formData = new FormData();
    Object.keys(pictureData).forEach((key) => {
      formData.append(key, pictureData[key]);
    });
    mutate({ businessId, form: formData });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      onSuccess(true);
    } else if (isError) {
      onSuccess(false);
    }
  }, [isError, isSuccess]);

  return (
    <InfoModal
      width="w-[40%] min-w-[400px] max-[550px]:w-[100%] max-[550px]:min-w-[150px]"
      className="h-fit "
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white p-8 max-[550px]:p-0 font-circular text-2xl font-normal text-changeProfileModalText">
        <div className="flex flex-col">
          <div className="max-w-xs py-5 text-left">
            <p className="text-xl font-medium text-black font-inter">
              Change Profile Picture
            </p>
            <p className="text-sm font-normal font-inter text-black/40">
              Upload profile picture
            </p>
          </div>

          <div className="w-full mx-auto">
            <form>
              <input
                hidden
                id="upload"
                {...getInputProps()}
                type="file"
                // className="hidden"
                accept="image/png,image/jpeg,image/jpg"
              />
              {base64ImageSrc ? (
                <div className="flex flex-col items-center p-5 mb-10 border border-dashed rounded-lg border-black/20">
                  <img
                    className="w-1/2 h-1/2"
                    src={base64ImageSrc?.toString()}
                    alt="Profile"
                  />
                </div>
              ) : (
                <div {...getRootProps()}>
                  <label htmlFor="upload">
                    <span className="text-sm font-medium text-black font-inter">
                      Profile pciture
                    </span>
                    <div className="mb-10 border border-dashed rounded-lg cursor-pointer border-black/20 py-11">
                      <div className="flex flex-col items-center justify-center">
                        <Icon name={Icons.Camera} />
                        <p className="mt-2 text-sm font-medium text-black font-inter">
                          Choose from gallery
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              )}

              <Button
                type="button"
                variant="primary"
                size="lg"
                label="Upload"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
