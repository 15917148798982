import { useEffect, useState } from 'react';
import { InfoModal } from './InfoModal';
import TextField from '../Form/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Paystack from '../Paystack';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../utils';
import Button from '../Form/Button';
import { usePlanSubscription } from '../../hooks/queries-and-mutations/subscription';
import { PaystackSuccessDto } from '../../types/payment.type';
import { getCostOfSeats } from '../../Api/apiCalls';
type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  planId: number;
  businessId: string;
  email: string;
};

export default function AddSeatPaymentModal({
  onCancel,
  onSuccess,
  businessId,
  email,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsSubmitting] = useState(false);
  const [costOfSeat, setCostOfSeat] = useState(0);

  const formik = useFormik({
    initialValues: {
      plan: '',
      period: '',
      seat: '',
    },
    validationSchema: Yup.object({
      plan: Yup.string().required('Plan is required').nullable(),
      period: Yup.string().required('Period is required').nullable(),
      seat: Yup.string().required('Number of seat is required').nullable(),
    }),
    onSubmit: (values, { setFieldTouched }) => {
      setIsSubmitting(true);
      for (const key in values) {
        setFieldTouched(key, true);
      }
    },
  });

  const { mutate, isSuccess, isError } = usePlanSubscription();

  const onPaymentSuccess = (payload: PaystackSuccessDto) => {
    const seatNumber = Number(formik.values.seat);
    if (isNaN(seatNumber)) {
      toast.error('Invalid seat number');
      return;
    } else if (payload.message === 'Approved' && payload.status === 'success') {
      mutate({
        businessId: businessId,
        reference: payload.reference,
        seat: seatNumber,
      });
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('Subscription complete');
      onSuccess();
    } else if (isError) {
      toast.error('Error');
      onCancel();
    }
  }, [isError, isSuccess]);

  const getCost = async (businessId: string, seatId: string) => {
    const res = await getCostOfSeats(businessId, seatId);
    setCostOfSeat(res);
  };

  useEffect(() => {
    getCost(businessId, formik.values.seat);
  }, [businessId, formik.values.seat]);

  return (
    <div>
      <InfoModal
        width="w-[50%] max-[850px]:w-[70%] max-[580px]:w-[100%] "
        className="h-fit "
        onClose={onCancel}
        usePadding={false}
      >
        <div className="rounded-[20px] bg-white font-circular text-2xl font-normal text-[#353535]">
          <div className="flex max-[1100px]:flex-wrap w-full justify-between">
            <div className="mb-6 min-[1100px]:w-[50%] max-[1100px]:w-[95%]">
              <div className="py-8 min-[1100px]:px-8 max-[1100px]:px-4">
                <p className="text-2xl font-semibold text-black">Add seat</p>
                <p className="text-sm font-normal text-black/40">
                  Select a plan and enter number of seats.
                </p>
              </div>
              <form
                className="min-[1100px]:pl-8 max-[1100px]:pl-4"
                onSubmit={formik.handleSubmit}
              >
                <div className="mb-5">
                  <TextField
                    name="plan"
                    type="number"
                    value="Premium plan"
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Plan"
                    disabled
                    placeholder="Premium plan"
                    error={formik.touched.plan ? formik.errors.plan : ''}
                  />
                </div>
                <div className="grid grid-cols-1 gap-4 mb-5 sm:grid-cols-2">
                  <div>
                    <TextField
                      name="period"
                      type="number"
                      value="Monthly"
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Period"
                      disabled
                      placeholder="Monthly"
                      error={formik.touched.period ? formik.errors.period : ''}
                    />
                  </div>
                  <div>
                    <TextField
                      name="seat"
                      type="number"
                      value={formik.values.seat}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Seat"
                      error={formik.touched.seat ? formik.errors.seat : ''}
                      requiredIndicator
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="w-[45%] min-w-[250px] max-[1100px]:w-full max-w-fits px-3 bg-searchInputBg rounded-[20px] pb-24">
              <div className="p-8">
                <p className="text-2xl font-semibold text-center text-black">
                  Order Summary
                </p>
              </div>
              <div className="mt-6 flex justify-between px-4 font-medium text-sm text-addSeatPaymentText">
                <div className="w-full space-y-7">
                  <div className="flex items-center justify-between">
                    <div>Price/person</div>
                    <div className="text-black">
                      {formik.values.seat === ''
                        ? formatCurrency('NGN', 0)
                        : formatCurrency('NGN', costOfSeat)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Billing</div>
                    <div className="text-black">Monthly</div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Number of seat</div>
                    <div className="text-black">{formik.values.seat}</div>
                  </div>
                  <hr className="border-[#0000001A]" />
                  <div className="flex items-center justify-between mt-4">
                    <div>Sub total</div>
                    <div className="text-black">
                      {formik.values.seat === ''
                        ? formatCurrency('NGN', 0)
                        : formatCurrency('NGN', costOfSeat)}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Tax</div>
                    <div className="text-black">{formatCurrency('NGN', 0)} </div>
                  </div>
                  <hr className="border-[#0000001A]" />
                  <div className="flex items-center justify-between mt-4 text-sm font-medium text-black">
                    <div>Total</div>
                    <div>
                      {formik.values.seat === ''
                        ? formatCurrency('NGN', 0)
                        : formatCurrency('NGN', costOfSeat)}
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.seat === '' ? (
                <Button
                  type="button"
                  variant="primary"
                  size="lg"
                  label="Subscribe"
                  className="mt-10"
                  disabled={true}
                />
              ) : (
                <Paystack
                  isProcessing={false}
                  onPaymentSuccess={onPaymentSuccess}
                  onPaymentClose={() => {
                    toast.error('Payment close');
                  }}
                  amount={costOfSeat}
                  email={email}
                  text="Suscribe"
                  className="mt-10 flex font-medium rounded-lg justify-center items-center px-6 py-3.5 text-base w-full cursor-pointer bg-demoBtnBg text-white border border-gray-500"
                />
              )}

              <p className="text-black/60 text-[10px] font-normal text-center">
                By continuing, you agree to the Paytton Terms and Condition
              </p>
            </div>
          </div>
        </div>
      </InfoModal>
    </div>
  );
}
