import React, { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../Components/Icon';
import AddNewRoleModal from '../../../../Components/Modal/AddNewRoleModal';
import AddNewEmployee from '../../../../Components/Modal/AddNewEmployee';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useGetRole } from '../../../../hooks/queries-and-mutations/settings';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import { RoleDoc } from '../../../../types/setting.type';
import { useGetEmployee } from '../../../../hooks/queries-and-mutations/business';
import { EmployeeDoc } from '../../../../types/business.type';
import {
  archiveEmployeeDetails,
  disableRole,
  reActivateEmployeeDetails,
} from '../../../../Api/apiCalls';
import { toast } from 'react-toastify';
import DisableModal from '../../../../Components/Modal/DisableModal';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import DisabledSuccessModal from '../../../../Components/Modal/Collaborator/DisabledSuccessModal';
import { AnimatePresence } from 'framer-motion';
import DropDown from '../../../../Components/Animations/DropDown';
import { useGlobalContext } from '../../../../Context/Context';

const EnhancedAddNewRoleModal = withCreatePortal(AddNewRoleModal);
const EnhancedAddNewEmployeeModal = withCreatePortal(AddNewEmployee);
const EnhancedConfirmArchiveModal = withCreatePortal(DisableModal);
const EnhancedSuccessModal = withCreatePortal(DisabledSuccessModal);

export default function Collaborators() {
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddEmployer, setShowAddEmployer] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleDoc | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeDoc | null>(null);
  const [accordian, setAccordian] = useState({
    role: false,
    employee: false,
  });
  const [confirmArchiveRoleModal, setConfirmArchiveRoleModal] = useState(false);
  const [disableEmployeeModal, setDisableEmployeeModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('');
  const [unDisableEmployeeModal, setUnDisableEmployeeModal] = useState<boolean>(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const { docs } = useAppSelector(selectBusiness);
  const { openCollaborators, setOpenCollaborators } = useGlobalContext();

  const { data: roles, refetch } = useGetRole({
    businessId: docs[0].businessID._id,
    page: 1,
  });

  const { data: employees, refetch: getEmployee } = useGetEmployee({
    businessId: docs[0].businessID._id,
  });

  const handleAccordian = (field: string, value: boolean) => {
    setOpenCollaborators(false);
    setAccordian((prevState) => ({ ...prevState, [field]: !value }));
  };

  const [employeeRoles, setEmployeeRoles] = useState(roles?.docs ?? []);

  const [employeeDetails, setEmployeeDetails] = useState(
    employees?.docs ?? [] ? employees?.docs : undefined
  );
  const [reversedEmployeeDetails, setReversedEmployeeDetails] = useState(
    employees?.docs ?? [] ? employees?.docs : undefined
  );
  useEffect(() => {
    setEmployeeRoles(roles?.docs ?? []);
    setEmployeeDetails(employees?.docs);
  }, [roles, employees]);

  useEffect(() => {
    if (employeeDetails) {
      setReversedEmployeeDetails([...employeeDetails].reverse());
    }
  }, [employeeDetails]);

  const disableARole = async (businessId: string, id: string) => {
    if (id) {
      const res = await disableRole(businessId, id);

      if (res && res.status === 201 && res.data) {
        setConfirmArchiveRoleModal(false);
        setSuccessfulModal(true);
        setSelectedRoleId('');
        toast.success('Role has been successfully disabled');
        setEmployeeRoles((prev) => prev?.filter((res) => res._id !== id));
      }
    }
  };

  const disableEmployee = async (userId: string) => {
    if (userId) {
      const response = await archiveEmployeeDetails(docs[0].businessID._id, userId);

      if (response?.data && response.status === 200) {
        setSuccessfulModal(true);
        toast.success('Employee has been disabled successfully');
        setEmployeeDetails((prev: EmployeeDoc[] | undefined) => {
          return prev?.map((employee) => {
            if (employee.userID === userId) {
              return {
                ...employee,
                blocked: true,
              };
            }
            return employee;
          });
        });
        refetch();
        setSelectedEmployeeId('');
        setDisableEmployeeModal(false);
      }
    }
  };

  const reactivateEmployee = async (id: string) => {
    if (id) {
      const response = await reActivateEmployeeDetails(docs[0].businessID._id, id);
      if (response?.data && response.status === 200) {
        toast.success(
          `Employee ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} has been reactivated successfully`
        );
        setEmployeeDetails((prev) => {
          return prev?.map((employee) => {
            if (employee.userID === response.data.data.userID) {
              return {
                ...employee,
                blocked: false,
              };
            }
            return employee;
          });
        });
        refetch();
        setSelectedEmployeeId('');
        setSelectedEmployee(null);
        setUnDisableEmployeeModal(false);
      }
    }
  };

  useEffect(() => {
    if (reversedEmployeeDetails && openCollaborators) {
      handleAccordian('employee', accordian.employee);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [reversedEmployeeDetails, openCollaborators]);

  return (
    <div className="w-full overflow-auto">
      <div className="border-b min-[580px]:pl-9 max-[580px]:pl-4 py-5">
        <p className="font-semibold text-2xl font-inter text-black">Collaborators</p>
      </div>
      <div className="py-8 min-[580px]:px-8 max-[590px]:p-4">
        {/* Roles */}
        <CheckPermissions
          requiredPermissions={['create_business_role', 'update_business_role']}
        >
          <div className="pb-5 border-b">
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleAccordian('role', accordian.role)}
              className="flex items-center justify-between"
            >
              <span className="text-xl text-black font-inter font-semibold">Roles</span>
              <button type="button" className={`${accordian.role ? 'rotate-180' : ''}`}>
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            <div className="mt-4 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
              <AnimatePresence>
                {accordian.role && (
                  <DropDown>
                    <div className="rounded table-responsive">
                      <table>
                        <thead className="bg-[#0353A4B0] w-full text-white rounded mb-3">
                          <tr>
                            <th>Roles</th>
                            <th>Permissions</th>
                            <th className="text-center">Action</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="bg-[#B9D6F224]">
                          {(employeeRoles ?? [])?.map((item) => (
                            <tr key={item._id}>
                              <td className="min-w-[200px]">{item.name}</td>
                              <td className="min-w-[300px]">
                                {item.permissions
                                  .map((permission: string) =>
                                    permission.replace(/_/g, ' ')
                                  )
                                  .join(', ')}{' '}
                              </td>
                              <td className="flex items-center justify-center p-3 text-center divide-x space-x-3">
                                {!item.permissions.includes('*') && (
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setSelectedRole(item);
                                        setShowAddRole(true);
                                      }}
                                    >
                                      <Icon name={Icons.EditPencil} />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setConfirmArchiveRoleModal(true);
                                        setSelectedRoleId(item?._id);
                                        setSelectedRole(item);
                                      }}
                                      className="pl-3 text-red-700"
                                      type="button"
                                    >
                                      {<AiOutlineEyeInvisible />}
                                    </button>
                                  </div>
                                )}
                              </td>
                              <td className="h-1"></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {!(roles?.docs ?? []).length && (
                        <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                          <p className="text-lg font-medium font-inter">
                            No role added yet
                          </p>
                          <div
                            role="button"
                            tabIndex={0}
                            className="flex items-center gap-1 mt-3 cursor-pointer"
                            onClick={() => setShowAddRole(true)}
                          >
                            <Icon
                              name={Icons.Add}
                              fill="#0353A4"
                              width={16}
                              height={16}
                            />
                            <span className="text-[#0353A4] text-sm font-medium font-inter">
                              Add new role
                            </span>
                          </div>
                        </tbody>
                      )}
                    </div>
                    <div className="flex items-center mt-8 pb-9">
                      <div
                        tabIndex={0}
                        className="flex items-center gap-1"
                        role="button"
                        onClick={() => setShowAddRole(true)}
                      >
                        <Icon name={Icons.Add} />
                        <span className="text-sm min-w-[130px] font-medium text-[#000000]">
                          Add another role
                        </span>
                      </div>
                      <div className="line ml-3" />
                    </div>
                  </DropDown>
                )}
              </AnimatePresence>
            </div>
          </div>
        </CheckPermissions>

        {/* Employees */}
        <CheckPermissions
          requiredPermissions={['view_business_member', 'update_business_member']}
        >
          <div className="pb-5 border-b mt-9">
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleAccordian('employee', accordian.employee)}
              className="flex items-center justify-between"
            >
              <span className="text-xl font-semibold text-black font-inter">
                Employees
              </span>
              <button
                type="button"
                className={`${accordian.employee ? 'rotate-180' : ''}`}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            <AnimatePresence>
              {accordian.employee ? (
                <DropDown>
                  <div className="pb-5 mt-9">
                    <div className="rounded table-responsive">
                      <table>
                        <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                          <tr>
                            <th className="">Name</th>
                            <th className="text-center">Roles</th>
                            <th className="text-center">Department</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody className="bg-[#B9D6F224]">
                          {(reversedEmployeeDetails ?? [])?.map((item) => (
                            <tr
                              key={item._id}
                              className={`${
                                item.blocked ? 'opacity-30 line-through ' : ''
                              }`}
                            >
                              <td className="whitespace-nowraps text-left min-w-[150px]">{`${item.firstName} ${item.lastName}`}</td>
                              <td className="whitespace-nowrap text-center">
                                {item.role}
                              </td>
                              <td className="whitespace-nowrap text-center min-w-[150px]">
                                {item.department?.name ?? 'All'}
                              </td>
                              <td className="whitespace-nowraps text-center min-w-[150px]">
                                <span
                                  className={`mr-2 inline-flex items-center justify-center w-3 h-3 ms-2 ${
                                    item.blocked ? '#F48D14E8' : 'bg-[#216509E8]'
                                  } rounded-full`}
                                ></span>
                                {item.blocked ? 'Inactive' : 'Active'}
                              </td>
                              <td className="flex justify-center items-center p-3 space-x-3 text-center divide-x">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowAddEmployer(true);
                                    setSelectedEmployee(item);
                                  }}
                                >
                                  <Icon name={Icons.EditPencil} />
                                </button>
                                {item.blocked ? (
                                  <button
                                    className="pl-3"
                                    type="button"
                                    onClick={() => {
                                      setUnDisableEmployeeModal(true);
                                      setSelectedEmployee(item);
                                      setSelectedEmployeeId(item.userID);
                                    }}
                                  >
                                    {<AiOutlineEye />}
                                  </button>
                                ) : (
                                  <button
                                    className="pl-3 text-red-600"
                                    type="button"
                                    onClick={() => {
                                      setDisableEmployeeModal(true);
                                      setSelectedEmployee(item);
                                      setSelectedEmployeeId(item.userID);
                                    }}
                                  >
                                    {<AiOutlineEyeInvisible />}
                                  </button>
                                )}
                              </td>
                              {/* <td className="h-1"></td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {!(reversedEmployeeDetails ?? []).length && (
                        <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                          <tr className="text-lg font-medium font-inter">
                            No employee added yet
                          </tr>
                          <div
                            role="button"
                            tabIndex={0}
                            className="flex items-center gap-1 mt-3 cursor-pointer"
                            onClick={() => setShowAddEmployer(true)}
                          >
                            <Icon
                              name={Icons.Add}
                              fill="#0353A4"
                              width={16}
                              height={16}
                            />
                            <span className="text-[#0353A4] text-sm font-medium font-inter">
                              Add new employee
                            </span>
                          </div>
                        </tbody>
                      )}
                    </div>
                    <div className="flex items-center mt-8 pb-9">
                      <div
                        tabIndex={0}
                        className="flex items-center gap-1"
                        role="button"
                        onClick={() => setShowAddEmployer(true)}
                      >
                        <Icon name={Icons.Add} />
                        <span className="text-sm min-w-[150px] font-medium text-[#000000]">
                          Add another employee
                        </span>
                      </div>
                      <div className="line ml-3" />
                    </div>
                  </div>
                </DropDown>
              ) : (
                ''
              )}
            </AnimatePresence>
          </div>
        </CheckPermissions>
      </div>
      {showAddRole && (
        <EnhancedAddNewRoleModal
          onCancel={() => {
            setShowAddRole(false);
            setSelectedRole(null);
          }}
          onSuccess={() => {
            setShowAddRole(false);
            setSelectedRole(null);
            refetch();
          }}
          businessId={docs[0]?.businessID?._id ?? ''}
          selectedRole={selectedRole}
        />
      )}
      {showAddEmployer && (
        <EnhancedAddNewEmployeeModal
          onCancel={() => {
            setSelectedEmployee(null);
            setShowAddEmployer(false);
          }}
          onSuccess={() => {
            setShowAddEmployer(false);
            setSelectedEmployee(null);
            getEmployee();
          }}
          selectedEmployee={selectedEmployee}
          setEmployeeDetails={setEmployeeDetails}
        />
      )}

      {confirmArchiveRoleModal && (
        <EnhancedConfirmArchiveModal
          onCancel={() => {
            setConfirmArchiveRoleModal(false);
            setSelectedRoleId('');
            setSelectedEmployee(null);
          }}
          onSuccess={() => {
            setConfirmArchiveRoleModal(false);
            setSelectedRoleId('');
            setSelectedEmployee(null);
          }}
          onConfirm={() => {
            disableARole(docs[0].businessID._id, selectedRoleId);
          }}
          content={`Are you sure you want to disable the ${selectedRole?.name} role?`}
          actionText="Disable"
          cancelText="Cancel"
          actionClassName="px-4 py-2 w-[150px] text-white rounded-md"
          cancelClassName="px-4 py-2 w-[150px] text-black rounded-md border-[1px] border-black border-solid bg-transparent"
        />
      )}

      {disableEmployeeModal && (
        <EnhancedConfirmArchiveModal
          onCancel={() => {
            setDisableEmployeeModal(false);
            setSelectedEmployeeId('');
            setSelectedEmployee(null);
          }}
          onSuccess={() => {
            setDisableEmployeeModal(false);
            setSelectedEmployee(null);
          }}
          onConfirm={() => {
            disableEmployee(selectedEmployeeId);
          }}
          content={`Are you sure you want to disable ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} as a collaborator?`}
          actionText="Yes"
          cancelText="Cancel"
          actionClassName="px-4 py-2 w-[150px] text-white rounded-md"
          cancelClassName="px-4 py-2 w-[150px] text-black rounded-md border-[1px] border-black border-solid bg-transparent"
        />
      )}
      {unDisableEmployeeModal && (
        <EnhancedConfirmArchiveModal
          onCancel={() => {
            setUnDisableEmployeeModal(false);
            setSelectedEmployeeId('');
          }}
          onSuccess={() => {
            setUnDisableEmployeeModal(false);
            setSelectedEmployeeId('');
          }}
          onConfirm={() => {
            reactivateEmployee(selectedEmployeeId);
          }}
          content={`Are you sure you want to re-activate ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} as a Collaborator?`}
          actionText="Activate"
          cancelText="Cancel"
          actionClassName="px-4 py-2 w-[150px] text-white rounded-md"
          cancelClassName="px-4 py-2 w-[150px] text-black rounded-md border-[1px] border-black border-solid bg-transparent"
        />
      )}
      {successfulModal && (
        <EnhancedSuccessModal
          onCancel={() => {
            setSelectedRoleId('');
            setSelectedRole(null);
            setSelectedEmployee(null);
            setSuccessfulModal(false);
          }}
          selectedId={selectedEmployeeId}
          title="Collaborator disabled successfully"
          description={`The collaborator ${selectedEmployee?.firstName} has been deactivated successfully`}
          cancelText="Back"
          selectedRole={selectedRole}
          isSuccessful={successfulModal}
        />
      )}
    </div>
  );
}
