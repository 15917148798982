import { InfoModal } from '../InfoModal';
import Button from '../../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type Props = {
  onCancel: () => void;
  onSuccess: (values: any) => void;
  isSubmitting: boolean;
};

export default function DeclineVendorModal({ onCancel, onSuccess, isSubmitting }: Props) {
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object({
      reason: Yup.string()
        .required('Tell us why you want to decline this invoice')
        .nullable(),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <InfoModal
      width="min-[1100px]:w-[45%] max-[1100px]:w-[60%] min-w-[500px] max-[650px]:min-w-[300px] max-[650px]:w-[90%]"
      className="h-fit "
      onClose={onCancel}
    >
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col justify-between">
          <div className="py-5 border-b">
            <p className="text-xl font-medium text-black font-inter">
              Are you sure you want to decline this vendor ?
            </p>
          </div>

          <form className="w-full pt-6 mt-5" onSubmit={formik.handleSubmit}>
            <textarea
              name="note"
              rows={5}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              placeholder="Tell us why you want to decline this invoice"
              className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.reason && formik.errors.reason
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
              defaultValue={formik.values.reason}
            />
            {formik.errors.reason && (
              <p className="mt-2 text-xs text-red-600 capitalize">
                {formik.errors.reason}
              </p>
            )}
            <div className="flex max-[480px]:flex-col-reverse items-center justify-center w-full min-[480px]:gap-5 mt-5">
              <Button
                type="button"
                variant="outline"
                size="md"
                label="Cancel"
                className="my-5 px-5 py-2.5 text-sm text-[#061A40] bg-transparent"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
                onClick={onCancel}
              />
              <Button
                type="submit"
                variant="custom"
                size="md"
                label="Decline"
                className="my-5 px-5 py-2.5 text-sm text-white bg-[#AA1414]"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </InfoModal>
  );
}
