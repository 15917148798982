import React, { useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import EmptyRecord from '../../../../Components/EmptyRecord';
import { Icon, Icons } from '../../../../Components/Icon';
import SearchInput from '../../../../Components/Form/SearchInput';
import TransactionDetailModal from '../../../../Components/Modal/Transaction/TransactionDetailModal';
import RateTransaction from '../../../../Components/Modal/Transaction/RateTransaction';
import { useGetTransactions } from '../../../../hooks/queries-and-mutations/transaction';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useAppSelector } from '../../../../store/hooks';
import Loader from '../../../../Assests/loader.gif';
import { TransactionDoc } from '../../../../types/transaction.type';
import Rating from '../../../../Components/Rating';
import { format } from 'date-fns';
import TransactionTableAction from '../../../../Components/TransactionTableAction';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import RateDetails from '../../../../Components/Modal/Transaction/RateDetails';
import { formatCurrency } from '../../../../utils';
import { SlOptionsVertical } from 'react-icons/sl';

const EnhancedTransactionDetail = withCreatePortal(TransactionDetailModal);
const EnhancedRateTransaction = withCreatePortal(RateTransaction);
const EnhancedRateDetails = withCreatePortal(RateDetails);

export default function Transactions() {
  const [showTranDetails, setShowTranDetails] = useState(false);
  const [showTranRating, setShowTranRating] = useState(false);
  const [showRatingDetails, setShowRatingDetails] = useState(false);
  const { docs } = useAppSelector(selectBusiness);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<TransactionDoc[] | null>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<TransactionDoc | null>(
    null
  );
  const [toggle, setToggle] = useState(false);
  const { data, isLoading, refetch } = useGetTransactions({
    businessId: docs[0].businessID._id,
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = data?.docs.filter(
      (item: any) =>
        item.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData as TransactionDoc[]);
  };

  return (
    <div>
      <Breadcrumb text="Transactions" />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <div className="w-1/3">
            <SearchInput
              value={search}
              placeholder="Search"
              name={''}
              className="h-10"
              onChange={(e) => handleSearch(e)}
              style="max-sm:flex sm:flex min-w-[200px]"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="overflow-x-auto mt-5 pb-32 panel bg-transBg rounded-[20px]">
            <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
              <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                <tr>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Vendor
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center min-w-[150px]">
                    Invoice no
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Amount
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Rating
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Upload
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filteredData || data?.docs)?.map((item) => (
                  <tr className="bg-white/60 " key={item._id}>
                    <th
                      scope="row"
                      className="p-5 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white min-w-[150px]"
                    >
                      {item.vendorName}
                    </th>
                    <td className="px-6 py-4 text-center">{item.invoiceNumber}</td>
                    <td className="px-6 py-4 text-center min-w-[200px]">
                      {format(new Date(item?.createdAt), 'dd MMM yyyy')}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {formatCurrency(
                        item?.currency === 'Dollars' ? 'usd' : item?.currency,
                        item.amount
                      )}
                    </td>
                    <td className="px-6 py-4 text-center">
                      <Rating rating={Number(item.rating?.buyer?.value) ?? '0'} />
                    </td>
                    <td className="px-6 py-4 relative !z-[1] text-center ">
                      <a
                        href={item.paymentProof}
                        rel="noreferrer"
                        download={item.paymentProof}
                        target="_blank"
                      >
                        <div className="flex items-center justify-center gap-1 text-[#0353A4] font-medium text-sm">
                          <Icon name={Icons.Attachment} /> <span>Attachment</span>
                        </div>
                      </a>
                    </td>
                    <td className="px-6 py-4 text-center relative">
                      {toggle ? (
                        <div
                          tabIndex={0}
                          role="button"
                          className="cursor-pointer flex justify-center"
                          onClick={() => {
                            setToggle(false);
                            setSelectedTransaction(null);
                          }}
                        >
                          <SlOptionsVertical />
                        </div>
                      ) : (
                        <div
                          tabIndex={0}
                          role="button"
                          className="cursor-pointer flex justify-center"
                          onClick={() => {
                            setToggle(true);
                            setSelectedTransaction(item);
                          }}
                        >
                          <SlOptionsVertical />
                        </div>
                      )}
                      {selectedTransaction?._id === item._id && (
                        <TransactionTableAction
                          onViewDetail={() => {
                            setShowTranDetails(true);
                          }}
                          onRateTransaction={() => setShowTranRating(true)}
                          onRatingDetails={() => setShowRatingDetails(true)}
                          setSelectedTransaction={setSelectedTransaction}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <Pagination
                currentPage={currentPage}
                totalCount={data?.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setCurrentPage(page)
                  paginationClick(page)
                }}
              /> */}
            {!(filteredData || data?.docs || [])?.length && (
              <EmptyRecord
                title="No transaction to show yet"
                content="You’ve got a  blank state. We’ll let you know when new transactions arrive"
                icon={Icons.Transaction}
              />
            )}
          </div>
        )}
      </div>

      {showTranDetails && (
        <EnhancedTransactionDetail
          transaction={selectedTransaction}
          onCancel={() => {
            // setSelectedTransaction(null);
            setShowTranDetails(false);
          }}
          onSuccess={() => {
            // setSelectedTransaction(null);
            setShowTranDetails(false);
          }}
        />
      )}
      {showTranRating && (
        <EnhancedRateTransaction
          onCancel={() => {
            setSelectedTransaction(null);
            setShowTranRating(false);
          }}
          onSuccess={() => {
            setSelectedTransaction(null);
            setShowTranRating(false);
            refetch();
          }}
          businessId={docs[0].businessID._id}
          transactionId={selectedTransaction?._id}
        />
      )}
      {showRatingDetails && (
        <EnhancedRateDetails
          onCancel={() => {
            setSelectedTransaction(null);
            setShowRatingDetails(false);
          }}
          transaction={selectedTransaction}
        />
      )}
    </div>
  );
}
