import React, { useEffect, useState } from 'react';
import Button from '../Form/Button';
import { InfoModal } from './InfoModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../service';
import { usePaystackPayment } from 'react-paystack';

type Config = {
  reference: string;
  email: string;
  amount: number;
  subaccount: string;
  publicKey: any; // Index signature for any additional properties
};

type Props = {
  title?: string;
  content: string;
  actionText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  cancelClassName?: string;
  actionClassName?: string;
  widthClass?: number;
  invoiceName: string;
  email: string;
  amount: number;
  continuePayment?: boolean;
  onBack?: () => void;
  onPaymentClose?: () => void;
  businessId: string;
  invoiceId?: string;
  currency: string;
  setIsSubmitting: (arg: boolean) => void;
};

const ConfirmPaymentModal: React.FC<Props> = ({
  content,
  title,
  actionText,
  cancelText,
  onConfirm,
  onCancel,
  isSubmitting,
  setIsSubmitting,
  cancelClassName,
  actionClassName,
  widthClass = 45,
  invoiceName,
  email,
  amount,
  continuePayment,
  onBack,
  businessId,
  invoiceId,
  currency,
}: Props) => {
  const [saveResponse, setSaveResponse] = useState<any>({});

  //Define a config file with the values
  const config: Config = {
    reference: saveResponse?.reference,
    email: email,
    amount: parseFloat(`${amount}`) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    subaccount: saveResponse?.subAccountCode,
  };

  //Pass the config we defined to the usePaystack hook
  const initializePayment: any = usePaystackPayment(config);

  //Runs on success
  const onSuccess = () => {
    onConfirmPayment();
  };

  //Runs onClose
  const onClose = () => {
    setSaveResponse({});
  };

  useEffect(() => {
    // Check if the payOnlineDetails state has been updated and is not an empty object
    if (Object.keys(saveResponse).length > 0) {
      // Call initializePayment with the updated payOnlineDetails
      initializePayment(onSuccess, onClose, saveResponse);
    }
  }, [saveResponse]);

  //Runs to confirm payment using the saved reference value
  const onConfirmPayment = async () => {
    const url = `${process.env.REACT_APP_API_URL}/invoice/${businessId}/payment-proof/${saveResponse.reference}/confirm`;
    try {
      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response.data) {
        toast.success('success! Payment approved successfully');
        setIsSubmitting(false);
        setSaveResponse({});
        onCancel();
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        toast.error('error', error?.response?.data.errors.join(','));
      } else {
        toast.error('An error occurred during payment confirmation');
      }
    }
  };

  //Called first
  const makePayment = async () => {
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('currency', currency);
      formData.append('paymentType', 'online_payment');
      formData.append('amount', `${amount}`);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/invoice/${businessId}/pay?id=${invoiceId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.data) {
        setSaveResponse(response.data.data); //Save the reference returned
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        toast.error(error?.response?.data.errors[0]);
      } else {
        toast.error('Sorry something has gone wrong');
      }
    }
  };

  return (
    <InfoModal
      width={`w-[${widthClass}%] max-[900px]:w-[70%] max-[600px]:w-[90%]`}
      className="h-fit "
      onClose={onCancel}
    >
      <div className="rounded-[20px] bg-white py-8 min-[600px]:px-8 max-[600px]:px-2 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          {!continuePayment ? (
            <h2 className="scroll-m-20 min-[600px]:text-3xl max-[600px]:text-[24px] text-center font-semibold tracking-tight first:mt-0">
              {title}{' '}
            </h2>
          ) : (
            <h2 className="scroll-m-20 min-[600px]:text-2xl max-[600px]:text-[20px] text-center font-semibold tracking-tight first:mt-0">
              Proceed with payment
            </h2>
          )}
          <div className="min-[450px]:max-w-xs max-[450px]:p-2 min-[450px]:p-5 text-center">
            {!continuePayment && (
              <p className="text-xl font-medium font-inter">
                {content}
                <span className="font-bold text-xl">{invoiceName} </span>{' '}
              </p>
            )}
          </div>

          <div className="flex max-[450px]:flex-col items-center justify-center gap-3 my-3 mt-10">
            {continuePayment ? (
              <Button
                variant="custom"
                size="custom"
                className={cancelClassName}
                label={'Back'}
                onClick={onBack}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            ) : (
              <Button
                variant="custom"
                size="custom"
                className={cancelClassName}
                label={cancelText || 'No'}
                onClick={onCancel}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            )}

            {!continuePayment && (
              <Button
                variant="custom"
                size="custom"
                className={actionClassName}
                label={actionText || 'Yes'}
                onClick={onConfirm}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            )}
            {continuePayment && (
              <Button
                variant="custom"
                size="custom"
                className={actionClassName}
                label={actionText || 'Yes'}
                onClick={makePayment}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </InfoModal>
  );
};

export default ConfirmPaymentModal;
