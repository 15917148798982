import React from 'react';
import { motion } from 'framer-motion';
import { TransactionDoc } from '../types/transaction.type';

interface IProps {
  onViewDetail: () => void;
  onRateTransaction: () => void;
  onRatingDetails: () => void;
  setSelectedTransaction?: (arg: TransactionDoc | null) => void;
}

const TransactionTableAction: React.FC<IProps> = ({
  onRateTransaction,
  onViewDetail,
  onRatingDetails,
}) => {
  return (
    <motion.div
      className="absolute right-[30px] bottom-[-150px] w-44 panel bg-white shadow-xl z-50 rounded"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
    >
      <div className="flex flex-col justify-between text-left space-y-6s">
        <button
          className="px-4 py-4 text-left text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onViewDetail}
        >
          View detail
        </button>
        <button
          className="px-4 py-4 text-left text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onRatingDetails}
        >
          View rating
        </button>
        <button
          className="px-4 py-4 text-left text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onRateTransaction}
        >
          Rate transaction
        </button>
      </div>
    </motion.div>
  );
};

export default TransactionTableAction;
