import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../../Components/Form/TextField';
import Button from '../../../Components/Form/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useForgetPassword } from '../../../hooks/queries-and-mutations/auth';
import { toast } from 'react-toastify';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import withCreatePortal from '../../../Components/Hoc/withCreatePortal';

const EnhancedSuccessModal = withCreatePortal(SuccessModal);

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { mutate, isSuccess, isError } = useForgetPassword();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const { email } = values;
      try {
        onSubmit(email);
      } catch (error) {
        console.log('An error occurred');
      }
    },
  });

  const onSubmit = (email: string) => {
    mutate(email);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('Reset link sent successfully');
      setShowSuccessModal(true);
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    if (isError) {
      toast.error('Failed to send reset link');
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  return (
    <>
      <div className="w-[430px] md:px-0 px-9">
        <div className="flex flex-col items-center">
          <p className="text-3xl font-medium">Forgot password</p>
        </div>
        <div className="relative w-full max-w-[870px] mt-5">
          <div className="relative flex flex-col justify-center rounded-3xl bg-[#0353A41A] px-12 py-16">
            <div className="mx-auto w-full max-w-[440px]">
              <form className="space-y-1" onSubmit={formik.handleSubmit}>
                <TextField
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // fixed typo here
                  label="Email"
                  error={formik.touched.email ? formik.errors.email : ''}
                />
                <div>
                  <div className="mt-4">
                    <p className="flex items-center justify-center cursor-pointer">
                      <span className="text-[#061A40] font-medium text-xs">
                        We will send a reset link to your email
                      </span>
                    </p>
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    label="Send Reset Link"
                    className="mt-10"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || isSubmitting}
                  />
                </div>
              </form>
              <div className="text-center text-[#00000066] text-xs mt-4">
                Don't have an account?
                <Link to="/auth/create-account" className="text-[#061A40] ml-1">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Reset Link Sent"
          description="Please click the link in the email sent to you to continue"
          cancelText="Return to Login"
          onCancel={() => {
            setShowSuccessModal(false);
            navigate('/auth/login');
          }}
        />
      )}
    </>
  );
}
